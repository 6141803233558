import React from "react";

// Customizable Area Start
import { Box, Grid, Typography, TextField, FormControl, FormLabel, Checkbox, FormControlLabel, Button, InputAdornment, IconButton } from "@mui/material";
import { imgLogin, imgLogo } from "./assets";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {Visibility as VisibilityIcon , VisibilityOff as VisibilityOffIcon} from '@mui/icons-material';
import * as Yup from 'yup';
// Customizable Area End

import EmailAccountLoginControllerWeb, {
  Props,
  configJSON,
} from "./EmailAccountLoginController.web";

export default class EmailAccountLogin extends EmailAccountLoginControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object({
    email: Yup.string()
      .email(configJSON.emailAddressValidError)
      .required(configJSON.emailRequiredError),
    password: Yup.string().required("Password is required"),
  });

  getInitialValues() {
    return {
      email: this.state.email,
      password: this.state.password,
      rememberMe: false,
    };
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { showPassword , emailError, passwordError } = this.state;
    const initialValues = this.getInitialValues();
    return (
      <Box sx={styles.loginMainContainer}>
        <Grid container spacing={2} sx={styles.mainGrid}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={styles.imageContainer}
          >
            <Box sx={styles.imageWrapper}>
              <img
                src={imgLogin}
                alt={configJSON.loginAlt}
                style={styles.image}
              />
              <Box sx={styles.logo}>
                <img src={imgLogo} alt="Logo"/>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box sx={styles.formContainer}>
              <Typography sx={styles.loginText}>
                {configJSON.labelTitle}
              </Typography>
              <Formik
                key={initialValues.email}
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
              >
                {({ errors, touched, isValid }) => (
                  <Form style={styles.loginForm}>
                    <FormControl sx={styles.loginFormControl}>
                      <FormLabel sx={styles.labelText}>
                        {configJSON.email}
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="email"
                        variant="outlined"
                        type="email"
                        placeholder="Enter Email"
                        fullWidth
                        sx={styles.fieldStyle}
                        error={touched.email && Boolean(errors.email) || !!emailError}
                        helperText={touched.email && Boolean(errors.email) ? <ErrorMessage name="email" /> : emailError}
                      />
                    </FormControl>
                    <FormControl sx={styles.loginFormControlPassword}>
                      <FormLabel sx={styles.labelText}>
                        Password
                      </FormLabel>
                      <Field
                        as={TextField}
                        name="password"
                        variant="outlined"
                        placeholder="Enter Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        sx={styles.fieldStyle}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="toggle-password-visibility"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityIcon sx={styles.iconColor}/> : <VisibilityOffIcon sx={styles.iconColor}/>}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={touched.password && Boolean(errors.password) || !!passwordError}
                        helperText={touched.password && Boolean(errors.password) ? <ErrorMessage name="password" /> : passwordError}
                      />
                    </FormControl>
                    <Box sx={styles.boxSpaceBetween}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            name="rememberMe"
                            color="primary"
                            sx={styles.checkBox}
                          />
                        }
                        label={configJSON.remember}
                        sx={styles.formControlLabel}
                      />
                      <Typography
                        component="a"
                        data-test-id="forgotpassword-text"
                        onClick={() => this.handleForgotPasswordNavigation()}
                        sx={styles.forgotPassword}
                      >
                        {configJSON.forgotPassword}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={styles.loginButton}
                      fullWidth
                      disabled={!isValid}
                    >
                      {configJSON.labelTitle}
                    </Button>
                    <Box sx={styles.boxCenter}>
                      <Typography sx={styles.signupText}>
                        {configJSON.dontHaveAnAccount}{" "}
                        <Typography
                          component="a"
                          data-test-id="signup-text"
                          sx={styles.signupLink}
                          onClick={() => this.handleSignupNavigation()}
                        >
                          {configJSON.signUp}
                        </Typography>
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  loginMainContainer: {
    padding:"34px 34px 20px",
    height: '100vh',
    overflow: 'hidden',
    "@media (max-width:899px)": {
      overflow: 'visible',
      padding: '34px',
      height:'100%',
    },
  },
  fieldStyle:{
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft: "5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: "8px",
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
  },
  iconColor: {
    color: '#94A3B8',
  },
  checkBox:{
    '&.Mui-checked': {
      color: 'green',
    },
  },
  mainGrid:{
    height: '100%',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  logo: {
    position: 'absolute',
    top: '25px',
    left: '25px',
    width: '50px',
    height: '50px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '0 120px',
    "@media (max-width:1199px)": {
      padding: '0 100px',
    },
    "@media (max-width:1099px)": {
      padding: '0 80px',
    },
    "@media (max-width:999px)": {
      padding: '0 60px',
    },
    "@media (max-width:899px)": {
      marginTop: '20px',
      padding: '0px 40px',
    },
    "@media (max-width:799px)": {
      padding: '0px 20px',
    },
    "@media (max-width:699px)": {
      padding: '0px',
    },
  },
  loginForm:{
    width: '100%',
  },
  loginText:{
    fontFamily: "Tiempos Headline",
    fontWeight: '700',
    fontSize:"30px",
    lineHeight:"40px",
    color: "#1C1917",
    marginBottom: '20px',
  },
  loginFormControl: {
    marginBottom: '20px',
    width: '100%',
  },
  loginFormControlPassword:{
    width: '100%',
  },
  labelText:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  error:{
    color:"red",
  },
  boxSpaceBetween:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  formControlLabel: {
    color: '#334155',
  },
  forgotPassword:{
    cursor:'pointer',
    color: "#1E293B",
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '22px',
    textDecoration: 'none',
    '&:hover': {
      cursor:'pointer',
    },
  },
  loginButton: {
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#ffffff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#94A3B8',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#94A3B8',
        color: '#ffffff',
      },
    },
  },
  boxCenter:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'10px',
  },
  signupText:{
    color: "#0F172A",
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  signupLink:{
    cursor:'pointer',
    color: "#1799CB",
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    textDecoration: 'none',
    '&:hover': {
      cursor:'pointer',
      color: "#1799CB",
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      textDecoration: 'none',
    },
  },
};
// Customizable Area End
