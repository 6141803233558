import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface CPTCodeAttributes {
  service_date: string;
  cpt_code: string;
  billed_charge: number;
  zip_code: string;
  payment: number;
  units: number;
  procedure_code_description: string;
}

interface CPTCodeData {
  id: string;
  type: string;
  attributes: CPTCodeAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isZipCodeEntered: boolean;
  zipCode: string;
  cptCode: string;
  zipCodeError: string;
  cptCodeError: string;
  token: string;
  cptCodeResponse: CPTCodeData[];
  selectedCPTCode: string;
  selectedCPTCodeId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CptCodeControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetCptCodeList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isZipCodeEntered: false,
      zipCode: "",
      cptCode: "",
      zipCodeError: "",
      cptCodeError: "",
      token: "",
      cptCodeResponse: [],
      selectedCPTCode: "",
      selectedCPTCodeId: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        responseJson.error &&
        apiRequestCallId === this.apiGetCptCodeList
      ) {
        this.setState({ cptCodeResponse: [], selectedCPTCode: "", selectedCPTCodeId: "" });
      }

      if (
        responseJson &&
        !responseJson.error &&
        apiRequestCallId === this.apiGetCptCodeList
      ) {
        this.setState({ cptCodeResponse: responseJson.data });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.checkTokenFromLocalStorage();
    return Promise.resolve()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.cptCode !== this.state.cptCode){
      this.GetCptCodeList()
    }
  }

  GetCptCodeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCptCodeList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCPTCodedataEndPoint}?zip_code=${this.state.zipCode}&cpt_code_keyword=${this.state.cptCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
  };

  handleSubmitZipCode = () => {
    const zipCode = this.state.zipCode;

    if (zipCode.trim() === "") {
      this.setState({ zipCodeError: configJSON.zipCodeRequiredError });
    } else {
      this.setState({ zipCodeError: "", isZipCodeEntered: true });
    }
  };

  handleSubmitCptCode = () => {
    const { cptCode, selectedCPTCode, zipCode } = this.state;

    if (cptCode.trim() !== "") {
      this.setState({ cptCodeError: "" });
      const selectedData = {
        cptCode: selectedCPTCode,
        zipCode: zipCode
      };
      setStorageData('selectedCptCodeData', JSON.stringify(selectedData))
      this.handleNavigationToSubscriberCost();
    } else {
      this.setState({ cptCodeError: configJSON.cptCodeRequiredError });
    }
  };

  handleNavigationToSubscriberCost = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriberCost");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleCptCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cptCode = event.target.value;
    this.setState({ cptCodeError: "", cptCode: cptCode });
  };

  handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zipCode = event.target.value;

    if (zipCode.trim().length < 5 || zipCode.trim().length > 5) {
      this.setState({
        zipCodeError: configJSON.zipCodeError,
        zipCode: zipCode,
      });
    } else {
      this.setState({ zipCodeError: "", zipCode: zipCode });
    }
  };

  handleRadioChange = (cptCode: string, id: string) => {
    this.setState({ selectedCPTCode: cptCode, selectedCPTCodeId: id, });
  };
  // Customizable Area End
}
