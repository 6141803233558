import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

interface SubscriptionPlan {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string | null;
  created_at: string;
  updated_at: string;
  cpt_code: number;
  plan1: boolean;
  plan2: boolean;
  features: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDeleteModal: boolean;
  isDeleteSuccessfullyModal: boolean;
  isLogoutModal: boolean;
  isLogoutSuccessfullyModal: boolean;
  isCancelModal: boolean;
  isEditModal: boolean;
  isRevokeModal: boolean;
  token: string;
  anchorEl: null | HTMLElement
  plans: SubscriptionPlan[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubscriptionBillingControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDeleteAccountId: string = '';
  apiGetPlanData: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isDeleteModal: false,
      isDeleteSuccessfullyModal: false,
      isLogoutModal: false,
      isLogoutSuccessfullyModal: false,
      isCancelModal: false,
      token: "",
      anchorEl: null,
      isEditModal: false,
      isRevokeModal: false,
      plans: [],
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && apiRequestCallId === this.apiDeleteAccountId) {
        this.handleDeleteSuccess();
      }

      if (responseJson && apiRequestCallId === this.apiGetPlanData) {
        this.setState({plans: responseJson.plans});
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.checkTokenFromLocalStorage();
    return Promise.resolve();
  }

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
  };

  deleteAccount = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteSuccess = () => {
    this.setState({ isDeleteSuccessfullyModal: true });
    this.handleRemoveStorageData();

    setTimeout(() => {
      this.handleLoginNavigation();
    }, 1000);
  }

  handleLogoutAccount = () => {
    this.setState({ isLogoutSuccessfullyModal: true });
    this.handleRemoveStorageData();

    setTimeout(() => {
      this.handleLoginNavigation();
    }, 1000);
  };

  handleRemoveStorageData = () => {
    removeStorageData("authToken");
    removeStorageData("userInfo");
  };

  handleLoginNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleDeleteSuccessfullyModalClose = () => {
    this.setState({ isDeleteSuccessfullyModal: false });
  }

  handleLogoutSuccessfullyModalClose = () => {
    this.setState({ isLogoutSuccessfullyModal: false });
  };

  handleOpenDeleteModal = () => {
    this.setState({ isDeleteModal: true });
  }

  handleDeleteModalClose = () => {
    this.setState({ isDeleteModal: false });
  }

  handleOpenLogoutModal = () => {
    this.setState({ isLogoutModal: true });
  }

  handleLogoutModalClose = () => {
    this.setState({ isLogoutModal: false });
  }

  handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOpenCancelModal = () => {
    this.setState({ isCancelModal: true });
  }

  handleCancelModalClose = () => {
    this.setState({ isCancelModal: false });
  }

  handleEditModalOpen = () => {
    this.getPlanData()
    this.setState({ isEditModal: true });
  }

  getPlanData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlanDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleEditModalClose = () => {
    this.setState({ isEditModal: false });
  }

  handleRevokeModalOpen = () => {
    this.setState({ isRevokeModal: true });
  }

  handleRevokeModalClose = () => {
    this.setState({ isRevokeModal: false });
  }
  // Customizable Area End
}
