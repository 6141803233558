import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Button,
  Tooltip,
  IconButton,
  Radio,
  Divider,
} from "@mui/material";
import { imgArrowForward, imgInfo } from "./assets";
import { withStyles } from "@mui/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
// Customizable Area End

import CptCodeControllerWeb, {
  Props,
  configJSON,
} from "./CptCodeController.web";

export default class CptCode extends CptCodeControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: false,
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isZipCodeEntered, cptCode, zipCode, zipCodeError, cptCodeError, cptCodeResponse, selectedCPTCodeId } =
      this.state;
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.container}>
          <Box sx={styles.innerContainer}>
            <Box sx={styles.box}>
              <Box sx={styles.boxMargin}>
                <Typography sx={styles.codeText} data-test-id="headerText">
                  {isZipCodeEntered
                    ? configJSON.cptcodeText
                    : configJSON.zipcodeText}
                  {isZipCodeEntered && (
                    <LightTooltip
                      title={configJSON.tooltipText}
                      arrow
                      placement="right-end"
                      data-test-id="tooltip"
                    >
                      <IconButton>
                        <img src={imgInfo} alt="Info Icon" />
                      </IconButton>
                    </LightTooltip>
                  )}
                </Typography>
              </Box>
              <Box sx={styles.boxWidth}>
                <Box sx={styles.boxMargin}>
                  {isZipCodeEntered ? (
                    <Box sx={styles.centerBox}>
                      <TextField
                        name="cptCode"
                        value={cptCode}
                        placeholder={configJSON.cptCodePlaceholder}
                        onChange={this.handleCptCodeChange}
                        error={!!cptCodeError}
                        sx={styles.textField}
                        fullWidth
                      />
                      {cptCodeError && (
                        <Box
                          sx={styles.errorMessageWidth}
                          data-test-id="cptCodeError"
                        >
                          <Box style={styles.error}>{cptCodeError}</Box>
                        </Box>
                      )}
                      {cptCodeResponse.length > 0 && (
                        <Box sx={styles.cptCodeListBox}>
                          {cptCodeResponse.map((cptCode) => (
                            <>
                              <Box sx={styles.cptCodeItem} key={cptCode.id}>
                                <Radio
                                  sx={styles.radioButton}
                                  data-test-id='cptCodeRadioButton'
                                  checked={
                                    selectedCPTCodeId ===
                                    cptCode.id
                                  }
                                  onChange={() =>
                                    this.handleRadioChange(
                                      cptCode.attributes.cpt_code,
                                      cptCode.id
                                    )
                                  }
                                />
                                <Box sx={styles.textContainer}>
                                  <Typography sx={styles.codeBoxText}>
                                    CPT CODE- {cptCode.attributes.cpt_code}
                                  </Typography>
                                  <Typography sx={styles.descriptionText}>
                                    {
                                      cptCode.attributes
                                        .procedure_code_description
                                    }
                                  </Typography>
                                </Box>
                                {selectedCPTCodeId ===
                                  cptCode.id && (
                                  <Button
                                    data-test-id="code-button"
                                    variant="contained"
                                    sx={styles.seeCostButton}
                                    endIcon={<img src={imgArrowForward} />}
                                    onClick={this.handleSubmitCptCode}
                                  >
                                    See Cost
                                  </Button>
                                )}
                              </Box>
                              <Box padding={"0px 16px"}>
                                <Divider />
                              </Box>
                            </>
                          ))}
                        </Box>
                      )}
                      <Button
                        disabled={!!cptCodeError || !selectedCPTCodeId}
                        type="submit"
                        variant="contained"
                        data-test-id="cptCodeSubmit"
                        sx={
                          cptCodeResponse.length > 0
                            ? styles.continueButtonBlueWithoutMarginTop
                            : styles.continueButtonBlue
                        }
                        fullWidth
                        onClick={this.handleSubmitCptCode}
                      >
                        {configJSON.continueBtnText}
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={styles.centerBox}>
                      <TextField
                        name="zipCode"
                        value={zipCode}
                        placeholder={configJSON.zipCodePlaceholder}
                        onChange={this.handleZipCodeChange}
                        error={!!zipCodeError}
                        sx={styles.textField}
                        fullWidth
                      />
                      {zipCodeError && (
                        <Box
                          sx={styles.errorMessageWidth}
                          data-test-id="zipCodeError"
                        >
                          <Box style={styles.error}>{zipCodeError}</Box>
                        </Box>
                      )}
                      <Button
                        disabled={!!zipCodeError}
                        type="submit"
                        variant="contained"
                        data-test-id="zipCodeSubmit"
                        sx={styles.continueButtonBlue}
                        fullWidth
                        onClick={this.handleSubmitZipCode}
                      >
                        {configJSON.continueBtnText}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  cptCodeListBox:{
    width: '100%',
    height: "160px",
    overflowY: "auto",
    backgroundColor: "white",
    marginTop: "5px",
    marginBottom: "10px",
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cptCodeItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  radioButton: {
    color: '#1799CB',
    '&.Mui-checked': {
      color: '#1799CB', 
    },
    marginBottom: '40px',
  },
  textContainer: {
    flex: 1,
  },
  codeBoxText: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#1E293B',
  },
  descriptionText: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#94A3B8',
  },
  seeCostButton: {
    backgroundColor: '#1799CB',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    padding: "5px 10px",
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
  },
  container: {
    padding: "45px 40px",
    borderRadius: "8px",
    height:'100%',
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ECFAFF",
    height:'100%',
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  centerBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  boxWidth: {
    width: "27vw",
    "@media (max-width: 1000px) and (min-width: 700px)": {
      width: "40vw",
    },
    "@media (max-width: 699px)": {
      width: "100%",
    },
  },
  boxMargin: {
    "@media (max-width: 640px)": {
      margin: "0px 40px",
    },
  },
  codeText: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "66px",
    color: "#000",
    marginBottom: "32px",
    textAlign: "center",
  },
  textField: {
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#CBD5E1",
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CBD5E1",
      },
    },
  },
  errorMessageWidth: {
    width: "100%",
  },
  error: {
    color: "red",
  },
  continueButtonBlue: {
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    backgroundColor: "#1799CB",
    color: "#fff",
    marginTop: "140px",
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#94A3B8",
    },
  },
  continueButtonBlueWithoutMarginTop: {
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    backgroundColor: "#1799CB",
    color: "#fff",
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#94A3B8",
    },
  },
};

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: "#fff",
    fontSize: "13px",
    padding: "10px",
    width: "220px",
    color: "#94A3B8",
  },
  arrow: {
    color: "#fff",
  },
})(Tooltip);
// Customizable Area End
