import React from "react";

// Customizable Area Start
import {
  Box,
  Tabs,
  Tab,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  InputAdornment, 
  IconButton,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import SubscriptionBilling from "../../subscriptionbilling2/src/SubscriptionBilling.web";
import { Formik, Form, Field } from 'formik';
import { Visibility as VisibilityIcon , VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import * as Yup from 'yup';
import { imgButton, imgCard, imgCheckmark, imgClose } from "./assets";
import { getErrorAndHelperText, validateExpirationYear, validateExpirationMonth } from "../../../components/src/Utility";

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}
// Customizable Area End

import UserProfileControllerWeb, {
  Props,
  configJSON,
} from "./UserProfileController.web";

export default class UserProfile extends UserProfileControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: false,
  };

  screenPropsForSubscriptionTab = {
    id: "SubscriptionBilling",
    navigation: this.props.navigation,
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z ]+$/, "First Name should only contain letters"),
    lastName: Yup.string().required("Last Name is required")
      .matches(/^[a-zA-Z ]+$/, "Last Name should only contain letters"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    zipCode: Yup.string()
      .required("Zip Code is required")
      .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits"),
    phoneNo: Yup.string()
      .required("Phone No. is required")
      .matches(/^\d{10}$/, "Phone No. must be exactly 10 digits"),
    password: Yup.string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must contain at least one capital letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/.{8,}/, "Password must be at least 8 characters long")
  });

  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  getInitialValues() {
    const { userDetails } = this.state;
    return {
      firstName: userDetails.first_name,
      lastName: userDetails.last_name,
      email: userDetails.email,
      password: userDetails.user_pass,
      zipCode: userDetails.zip_code,
      phoneNo: userDetails.phone_number,
    };
  }

  validationSchemaForCardDetails = Yup.object({
    cardholderName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Cardholder Name should only contain letters")
    .required('Cardholder Name is required'),
    expiration: Yup.string()
    .test('valid-expiration', 'Invalid format. Use MM/YYYY.', validateExpirationYear)
    .test('month-range', 'Month must be between 01 and 12.', validateExpirationMonth)
    .required('Expiration is required'),
    cardNumber: Yup.string()
    .matches(/^[\d\s]+$/, "Card Number must contain only numbers")
    .required("Card Number is required"),
    cvv: Yup.string()
    .matches(/^\d+$/, 'CVV must contain only numbers')
    .required('CVV is required'),
    country: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Country Name should only contain letters")
    .required('Country Name is required'),
  });

  getInitialValuesForCardDetails() {
    const { cardAndAddressDetails } = this.state;
    return {
      cardholderName: cardAndAddressDetails?.cardholder_name,
      cardNumber: cardAndAddressDetails?.card_number,
      expiration: cardAndAddressDetails?.expiration_date,
      cvv: cardAndAddressDetails?.cvv ? `${cardAndAddressDetails.cvv}` : "",
      country: cardAndAddressDetails?.country1,
    };
  }

  validationSchemaForAddress = Yup.object({
    address: Yup.string().required('Address is required'),
    city: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "City Name should only contain letters")
    .required('City Name is required'),
    state: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "State Name should only contain letters")
    .required('State Name is required'),
    zip: Yup.string()
    .matches(/^\d+$/, 'Zip Code must contain only numbers')
    .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits")
    .required('ZipCode is required'),
    country: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Country Name should only contain letters")
    .required('Country Name is required'),
  });

  getInitialValuesForAddress() {
    const { cardAndAddressDetails } = this.state;
    return {
      address: cardAndAddressDetails?.address_line,
      city: cardAndAddressDetails?.city,
      state: cardAndAddressDetails?.state,
      zip: cardAndAddressDetails?.zip,
      country: cardAndAddressDetails?.country2,
    };
  }

  renderIf = <P,>(condition: boolean, component: React.ReactElement<P>) => {
    return condition ? component : null;
  };

  renderStyleIf = (condition: boolean, style: React.CSSProperties): React.CSSProperties | undefined => {
    return condition ? style : undefined;
  };

  getPlaceholderText = (condition: boolean, placeholderText: string): string => {
    return condition ? placeholderText : "";
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      tabValue,
      showPassword,
      isEmailModelVisible,
      changedEmail,
      seconds,
      emailError,
      phoneError,
      changedValues,
      isEditCardDetailsVisible,
      isEditAddressVisible,
      isCardModelVisible,
      isAddressModelVisible,
      isCardAndAddressDetailsFounded,
      isEditButtonClickedForCard,
      isEditButtonClickedForAddress,
      cardNumberError,
      expirationError,
      cvvError,
      isCardDetailsUpdatedSuccessfully,
      isAddressDetailsUpdatedSuccessfully,
    } = this.state;
    const initialValues = this.getInitialValues();
    const initialValuesForCardDetails = this.getInitialValuesForCardDetails();
    const initialValuesForAddress = this.getInitialValuesForAddress();
    const timerStyle = {
      ...styles.timer,
      color: seconds === 0 ? 'red' : '#334155',
    };
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box
          sx={
            tabValue === 0
              ? styles.container
              : stylesForPaymentMethodPage.container
          }
        >
          <Box sx={styles.innerContainer}>
            <Box sx={styles.box}>
              <Tabs
                value={tabValue}
                onChange={this.handleTabChange}
                aria-label="tabs example"
                data-test-id="tabs"
                variant="fullWidth"
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                <Tab
                  label="Edit Profile"
                  sx={{ ...styles.tabRoot, ...styles.firstTab }}
                />
                <Tab label="Subscription" sx={styles.tabRoot} />
                <Tab
                  label="Payment Method"
                  sx={{ ...styles.tabRoot, ...styles.lastTab }}
                />
              </Tabs>
            </Box>
            {this.renderIf(
              tabValue === 0,
              <>
                <Box sx={styles.formikBox}>
                  <Formik
                    key={initialValues.firstName}
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleFormSubmit(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Box sx={styles.updateProfileForm}>
                        <Form>
                          <Grid container spacing={4} sx={styles.grid}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              sx={styles.gridStyles}
                            >
                              <FormControl sx={styles.updateprofileFormControl}>
                                <FormLabel sx={styles.labelTextUpdateProfie}>
                                  {configJSON.labelFirstName}
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="firstName"
                                  variant="outlined"
                                  type="firstName"
                                  placeholder="Enter First Name"
                                  fullWidth
                                  sx={styles.fieldStyle}
                                  error={
                                    getErrorAndHelperText(
                                      "firstName",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "firstName",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              sx={styles.gridStyles}
                            >
                              <FormControl sx={styles.updateprofileFormControl}>
                                <FormLabel sx={styles.labelTextUpdateProfie}>
                                  Password
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="password"
                                  variant="outlined"
                                  placeholder="Enter Password"
                                  type={showPassword ? "text" : "password"}
                                  fullWidth
                                  sx={styles.fieldStyle}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          data-test-id="toggle-password-visibility"
                                          aria-label="toggle password visibility"
                                          onClick={this.handleClickShowPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <VisibilityIcon
                                              sx={styles.iconColor}
                                            />
                                          ) : (
                                            <VisibilityOffIcon
                                              sx={styles.iconColor}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    getErrorAndHelperText(
                                      "password",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "password",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              sx={styles.gridStyles}
                            >
                              <FormControl sx={styles.updateprofileFormControl}>
                                <FormLabel sx={styles.labelTextUpdateProfie}>
                                  {configJSON.labelLastName}
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="lastName"
                                  variant="outlined"
                                  type="lastName"
                                  placeholder="Enter Last Name"
                                  fullWidth
                                  sx={styles.fieldStyle}
                                  error={
                                    getErrorAndHelperText(
                                      "lastName",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "lastName",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              sx={styles.gridStyles}
                            >
                              <FormControl sx={styles.updateprofileFormControl}>
                                <FormLabel sx={styles.labelTextUpdateProfie}>
                                  Zip Code
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="zipCode"
                                  variant="outlined"
                                  type="zipCode"
                                  placeholder="Enter First Name"
                                  fullWidth
                                  sx={styles.fieldStyle}
                                  error={
                                    getErrorAndHelperText(
                                      "zipCode",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "zipCode",
                                      errors as FormError,
                                      touched as FormErrorTouched
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              sx={styles.gridStyles}
                            >
                              <FormControl sx={styles.updateprofileFormControl}>
                                <FormLabel sx={styles.labelTextUpdateProfie}>
                                  Email
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="email"
                                  variant="outlined"
                                  type="email"
                                  placeholder="Enter Email"
                                  fullWidth
                                  sx={styles.fieldStyle}
                                  error={
                                    getErrorAndHelperText(
                                      "email",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      emailError
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "email",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      emailError
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              sx={styles.gridStyles}
                            >
                              <FormControl sx={styles.updateprofileFormControl}>
                                <FormLabel sx={styles.labelTextUpdateProfie}>
                                  Phone No.
                                </FormLabel>
                                <Field
                                  as={TextField}
                                  name="phoneNo"
                                  variant="outlined"
                                  type="number"
                                  placeholder="Enter Phone No."
                                  fullWidth
                                  sx={{
                                    ...styles.fieldStyle,
                                    "& input[type=number]": {
                                      "-moz-appearance": "textfield",
                                      "&::-webkit-outer-spin-button": {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                      "&::-webkit-inner-spin-button": {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                    },
                                  }}
                                  error={
                                    getErrorAndHelperText(
                                      "phoneNo",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      phoneError
                                    ).isError
                                  }
                                  helperText={
                                    getErrorAndHelperText(
                                      "phoneNo",
                                      errors as FormError,
                                      touched as FormErrorTouched,
                                      phoneError
                                    ).helperText
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Box sx={styles.buttonBox}>
                            <Button
                              variant="outlined"
                              sx={styles.cancelButton}
                              data-test-id="cancel-button"
                              onClick={() =>
                                this.handleNavigationToLandingPage()
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              sx={styles.submitButton}
                            >
                              Save Change
                            </Button>
                          </Box>
                        </Form>
                      </Box>
                    )}
                  </Formik>
                </Box>
                <Modal
                  open={isEmailModelVisible}
                  onClose={this.handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styles.modalInner}>
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      marginBottom={"10px"}
                    >
                      <img
                        src={imgClose}
                        style={styles.closeIcon}
                        data-test-id="close-icon"
                        onClick={() => this.handleModalClose()}
                      />
                    </Box>
                    <Box sx={styles.modal}>
                      <Typography sx={styles.modalHeaderTitle}>
                        Please Check your email
                      </Typography>
                      <Typography sx={styles.modalContext}>
                        We've sent a verification link to <b>{changedEmail}</b>{" "}
                        to confirm your email.
                      </Typography>
                      <Typography sx={timerStyle} data-test-id="timer-text">
                        {this.formatTime(seconds)}
                      </Typography>
                      <Box display={"flex"} justifyContent={"end"}>
                        <Button
                          variant="contained"
                          sx={styles.resendButton}
                          data-test-id="resend-button"
                          disabled={seconds !== 0}
                          onClick={() => this.handleSubmit(changedValues)}
                        >
                          Resend
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </>
            )}
            {this.renderIf(
              tabValue === 1,
              <SubscriptionBilling {...this.screenPropsForSubscriptionTab} />
            )}
            {this.renderIf(
              tabValue === 2,
              <Grid container spacing={4} sx={styles.grid}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  sx={styles.gridPadding}
                >
                  <Box
                    sx={this.renderStyleIf(
                      isEditAddressVisible,
                      styles.blurred
                    )}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom={"40px"}
                    >
                      <Typography
                        sx={styles.billingHeader}
                        textAlign="center"
                        flexGrow={1}
                      >
                        Add Card Details
                      </Typography>
                      <IconButton
                        data-test-id="edit-card"
                        onClick={() => this.editCardDetails()}
                        color="primary"
                        disabled={!isCardAndAddressDetailsFounded}
                      >
                        <img src={imgButton} alt="Edit" />
                      </IconButton>
                    </Box>
                    <Formik
                      key={`${initialValuesForCardDetails.cardNumber}-${initialValuesForCardDetails.cardholderName}-${initialValuesForCardDetails.country}-${initialValuesForCardDetails.cvv}-${initialValuesForCardDetails.expiration}`}
                      initialValues={initialValuesForCardDetails}
                      validationSchema={this.validationSchemaForCardDetails}
                      onSubmit={(values) =>
                        this.handleCardDetailsUpdate(values)
                      }
                    >
                      {({ errors, touched, handleChange, isValid, values }) => (
                        <Form>
                          <Box
                            sx={this.renderStyleIf(
                              isEditButtonClickedForCard,
                              styles.blurred
                            )}
                          >
                            <Grid
                              container
                              sx={{
                                ...styles.gridInnerPadding,
                                position:
                                  isEditAddressVisible ||
                                  isEditButtonClickedForCard
                                    ? "relative"
                                    : undefined,
                              }}
                            >
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    Cardholder Name
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="cardholderName"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter CardHolder Name"
                                    )}
                                    fullWidth
                                    sx={styles.fieldStyle}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <img src={imgCard} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      getErrorAndHelperText(
                                        "cardholderName",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "cardholderName",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    Card Number
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="cardNumber"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter Card Number"
                                    )}
                                    fullWidth
                                    data-test-id="cerdNumberTextField"
                                    value={values.cardNumber}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      this.onCardNumberChange();
                                      const inputValue =
                                        event.target.value.replace(/\s+/g, "");
                                      const formattedValue =
                                        inputValue
                                          .match(/.{1,4}/g)
                                          ?.join(" ") || inputValue;
                                      handleChange("cardNumber")({
                                        ...event,
                                        target: {
                                          ...event.target,
                                          value: formattedValue,
                                        },
                                      });
                                    }}
                                    sx={styles.fieldStyle}
                                    error={
                                      getErrorAndHelperText(
                                        "cardNumber",
                                        errors as FormError,
                                        touched as FormErrorTouched,
                                        cardNumberError
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "cardNumber",
                                        errors as FormError,
                                        touched as FormErrorTouched,
                                        cardNumberError
                                      ).helperText
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={styles.gridInnerPaddingRight}
                              >
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    Expiration
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="expiration"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "MM/YYYY"
                                    )}
                                    fullWidth
                                    sx={styles.fieldStyle}
                                    data-test-id="expirationTextField"
                                    value={values.expiration}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleChange("expiration")(event);
                                      this.onExpirationChange();
                                    }}
                                    error={
                                      getErrorAndHelperText(
                                        "expiration",
                                        errors as FormError,
                                        touched as FormErrorTouched,
                                        expirationError
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "expiration",
                                        errors as FormError,
                                        touched as FormErrorTouched,
                                        expirationError
                                      ).helperText
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={styles.gridInnerPaddingLeft}
                              >
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    CVV
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    variant="outlined"
                                    name="cvv"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter CVV"
                                    )}
                                    data-test-id="cvvTextField"
                                    type="password"
                                    fullWidth
                                    value={values.cvv}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleChange("cvv")(event);
                                      this.onCvvChange();
                                    }}
                                    helperText={
                                      getErrorAndHelperText(
                                        "cvv",
                                        errors as FormError,
                                        touched as FormErrorTouched,
                                        cvvError
                                      ).helperText
                                    }
                                    error={
                                      getErrorAndHelperText(
                                        "cvv",
                                        errors as FormError,
                                        touched as FormErrorTouched,
                                        cvvError
                                      ).isError
                                    }
                                    sx={{
                                      ...styles.fieldStyle,
                                      "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                        "&::-webkit-outer-spin-button": {
                                          "-webkit-appearance": "none",
                                          margin: 0,
                                        },
                                        "&::-webkit-inner-spin-button": {
                                          "-webkit-appearance": "none",
                                          margin: 0,
                                        },
                                      },
                                    }}
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    Country
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="country"
                                    type="text"
                                    variant="outlined"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter Country Name"
                                    )}
                                    sx={styles.fieldStyle}
                                    fullWidth
                                    helperText={
                                      getErrorAndHelperText(
                                        "country",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                    error={
                                      getErrorAndHelperText(
                                        "country",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <Box>
                                  <Grid container sx={styles.gridButtonPadding}>
                                    {this.renderIf(
                                      isEditCardDetailsVisible,
                                      <>
                                        <Grid
                                          item
                                          sx={styles.gridInnerPaddingRight}
                                          xs={6}
                                        >
                                          <Button
                                            variant="outlined"
                                            fullWidth
                                            sx={styles.cancelButtonFullwidth}
                                            data-test-id="edit-card-cancel"
                                            onClick={() =>
                                              this.editCardDetails()
                                            }
                                          >
                                            Cancel
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          sx={styles.gridInnerPaddingLeft}
                                        >
                                          <Button
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                            sx={styles.submitButtonFullwodth}
                                            data-test-id="edit-card-save"
                                            disabled={!isValid}
                                          >
                                            Save Change
                                          </Button>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Box>
                              </Grid>
                              <Modal
                                open={isCardModelVisible}
                                onClose={this.handleCardModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styles.modalInner}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"end"}
                                    marginBottom={"10px"}
                                  >
                                    <img
                                      src={imgClose}
                                      style={styles.closeIcon}
                                      data-test-id="card-modal-close"
                                      onClick={() =>
                                        this.handleCardModalClose()
                                      }
                                    />
                                  </Box>
                                  <Box sx={styles.modalAddressCard}>
                                    <Typography
                                      sx={styles.modalAddrerssCardContext}
                                    >
                                      Are you sure you want to{" "}
                                      <b>Change your Card details</b>
                                    </Typography>
                                    <Box display={"flex"} width={"60%"}>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          ...styles.cancelButtonFullwidth,
                                          mr: "10px",
                                        }}
                                        fullWidth
                                        data-test-id="card-modal-close-cancel"
                                        onClick={() =>
                                          this.handleCardModalClose()
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        data-test-id="yesButtonOfCard"
                                        sx={{
                                          ...styles.submitButtonFullwodth,
                                          ml: "10px",
                                        }}
                                        onClick={() =>
                                          this.handleCardUpdateConfirm()
                                        }
                                      >
                                        Yes
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Modal>
                              {this.renderIf(
                                isEditButtonClickedForCard,
                                <Box sx={styles.overlay1}></Box>
                              )}
                              {this.renderIf(
                                isEditAddressVisible,
                                <Box sx={styles.overlay}></Box>
                              )}
                            </Grid>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Grid>
                <Modal
                  open={isCardDetailsUpdatedSuccessfully}
                  onClose={this.handleCardDetailsUpdatedSuccessfullyModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styles.modalContactUsInner}>
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      marginBottom={"10px"}
                    >
                      <img
                        src={imgClose}
                        style={styles.closeDeleteIcon}
                        data-test-id="carddetails-successfully-modal-close"
                        onClick={() =>
                          this.handleCardDetailsUpdatedSuccessfullyModalClose()
                        }
                      />
                    </Box>
                    <Box sx={styles.modalContactUsSuccessfully}>
                      <Box marginBottom={"50px"}>
                        <img src={imgCheckmark} />
                      </Box>
                      <Typography sx={styles.contactUsSuccessfullyText}>
                        Your Card details have
                      </Typography>
                      <Typography sx={styles.contactUsSuccessfullyText}>
                        been successfully updated.
                      </Typography>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={styles.okayButton}
                        data-test-id="carddetails-okay-modal-close"
                        onClick={() =>
                          this.handleCardDetailsUpdatedSuccessfullyModalClose()
                        }
                      >
                        Okay
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  sx={styles.gridPadding}
                >
                  <Box
                    sx={this.renderStyleIf(
                      isEditCardDetailsVisible,
                      styles.blurred
                    )}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom={"40px"}
                    >
                      <Typography
                        sx={styles.billingHeader}
                        textAlign="center"
                        flexGrow={1}
                      >
                        Add Billing Address
                      </Typography>
                      <IconButton
                        data-test-id="edit-address"
                        onClick={() => this.editAddressDetails()}
                        color="primary"
                        disabled={!isCardAndAddressDetailsFounded}
                      >
                        <img src={imgButton} alt="Edit" />
                      </IconButton>
                    </Box>
                    <Formik
                      key={`${initialValuesForAddress.address}-${initialValuesForAddress.city}-${initialValuesForAddress.country}-${initialValuesForAddress.state}-${initialValuesForAddress.zip}`}
                      initialValues={initialValuesForAddress}
                      validationSchema={this.validationSchemaForAddress}
                      onSubmit={(values) =>
                        this.handleAddressDetailsUpdate(values)
                      }
                    >
                      {({ errors, touched, isValid }) => (
                        <Form>
                          <Box
                            sx={this.renderStyleIf(
                              isEditButtonClickedForAddress,
                              styles.blurred
                            )}
                          >
                            <Grid
                              sx={{
                                ...styles.gridInnerPadding,
                                position:
                                  isEditCardDetailsVisible ||
                                  isEditButtonClickedForAddress
                                    ? "relative"
                                    : undefined,
                              }}
                              container
                            >
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    Address
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="address"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter Address"
                                    )}
                                    fullWidth
                                    disabled={!isCardAndAddressDetailsFounded}
                                    sx={styles.fieldStyle}
                                    error={
                                      getErrorAndHelperText(
                                        "address",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "address",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    City
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="city"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter City Name"
                                    )}
                                    fullWidth
                                    sx={styles.fieldStyle}
                                    disabled={!isCardAndAddressDetailsFounded}
                                    error={
                                      getErrorAndHelperText(
                                        "city",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "city",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={styles.gridInnerPaddingRight}
                              >
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    State
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="state"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter State Name"
                                    )}
                                    fullWidth
                                    sx={styles.fieldStyle}
                                    error={
                                      getErrorAndHelperText(
                                        "state",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                    helperText={
                                      getErrorAndHelperText(
                                        "state",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={styles.gridInnerPaddingLeft}
                              >
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    ZIP
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="zip"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter Zip Code"
                                    )}
                                    fullWidth
                                    sx={styles.fieldStyle}
                                    error={
                                      getErrorAndHelperText(
                                        "zip",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "zip",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <FormControl sx={styles.cardDetailsFormControl}>
                                  <FormLabel sx={styles.labelTextUpdateProfie}>
                                    Country
                                  </FormLabel>
                                  <Field
                                    as={TextField}
                                    name="country"
                                    variant="outlined"
                                    type="text"
                                    placeholder={this.getPlaceholderText(
                                      isCardAndAddressDetailsFounded,
                                      "Enter Country Name"
                                    )}
                                    fullWidth
                                    sx={styles.fieldStyle}
                                    error={
                                      getErrorAndHelperText(
                                        "country",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).isError
                                    }
                                    helperText={
                                      getErrorAndHelperText(
                                        "country",
                                        errors as FormError,
                                        touched as FormErrorTouched
                                      ).helperText
                                    }
                                    disabled={!isCardAndAddressDetailsFounded}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={styles.gridInnerPadding}>
                                <Box>
                                  <Grid container sx={styles.gridButtonPadding}>
                                    {this.renderIf(
                                      isEditAddressVisible,
                                      <>
                                        <Grid
                                          item
                                          xs={6}
                                          sx={styles.gridInnerPaddingRight}
                                        >
                                          <Button
                                            variant="outlined"
                                            fullWidth
                                            sx={styles.cancelButtonFullwidth}
                                            data-test-id="edit-address-cancel"
                                            onClick={() =>
                                              this.editAddressDetails()
                                            }
                                          >
                                            Cancel
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          sx={styles.gridInnerPaddingLeft}
                                        >
                                          <Button
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                            sx={styles.submitButtonFullwodth}
                                            data-test-id="edit-address-save"
                                            disabled={!isValid}
                                          >
                                            Save Change
                                          </Button>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Box>
                              </Grid>
                              <Modal
                                open={isAddressModelVisible}
                                onClose={this.handleAddressModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styles.modalInner}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"end"}
                                    marginBottom={"10px"}
                                  >
                                    <img
                                      src={imgClose}
                                      style={styles.closeIcon}
                                      data-test-id="address-modal-close"
                                      onClick={() =>
                                        this.handleAddressModalClose()
                                      }
                                    />
                                  </Box>
                                  <Box sx={styles.modalAddressCard}>
                                    <Typography
                                      sx={styles.modalAddrerssCardContext}
                                    >
                                      Are you sure you want to{" "}
                                      <b>Change your Address details</b>
                                    </Typography>
                                    <Box display={"flex"} width={"60%"}>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          ...styles.cancelButtonFullwidth,
                                          mr: "10px",
                                        }}
                                        fullWidth
                                        data-test-id="address-modal-close-cancel"
                                        onClick={() =>
                                          this.handleAddressModalClose()
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        data-test-id="yesButtonOfAddress"
                                        sx={{
                                          ...styles.submitButtonFullwodth,
                                          ml: "10px",
                                        }}
                                        onClick={() =>
                                          this.handleAddressUpdateConfirm()
                                        }
                                      >
                                        Yes
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Modal>
                              {this.renderIf(
                                isEditButtonClickedForAddress,
                                <Box sx={styles.overlay1}></Box>
                              )}
                              {this.renderIf(
                                isEditCardDetailsVisible,
                                <Box sx={styles.overlay}></Box>
                              )}
                            </Grid>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Grid>
                <Modal
                  open={isAddressDetailsUpdatedSuccessfully}
                  onClose={
                    this.handleAddressDetailsUpdatedSuccessfullyModalClose
                  }
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styles.modalContactUsInner}>
                    <Box
                      display={"flex"}
                      justifyContent={"end"}
                      marginBottom={"10px"}
                    >
                      <img
                        src={imgClose}
                        style={styles.closeDeleteIcon}
                        data-test-id="addressdetails-successfully-modal-close"
                        onClick={() =>
                          this.handleAddressDetailsUpdatedSuccessfullyModalClose()
                        }
                      />
                    </Box>
                    <Box sx={styles.modalContactUsSuccessfully}>
                      <Box marginBottom={"50px"}>
                        <img src={imgCheckmark} />
                      </Box>
                      <Typography sx={styles.contactUsSuccessfullyText}>
                        Your address has been
                      </Typography>
                      <Typography sx={styles.contactUsSuccessfullyText}>
                        successfully updated
                      </Typography>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={styles.okayButton}
                        data-test-id="addressdetails-okay-modal-close"
                        onClick={() =>
                          this.handleAddressDetailsUpdatedSuccessfullyModalClose()
                        }
                      >
                        Okay
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </Grid>
            )}
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const stylesForPaymentMethodPage = {
  container: {
    padding: "45px 40px",
    borderRadius: "8px", 
  },
}
const styles = {
  okayButton:{
    cursor: 'pointer',
    width:"25%",
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    marginTop: "30px"
  },
  modalContactUsInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "800px",
    transform: "Translate(-50%, -50%)",
  },
  modalContactUsSuccessfully:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"90px 140px",
  },
  closeDeleteIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  contactUsSuccessfullyText:{
    fontSize: "30px",
    fontWeight: "700",
    color: "#1C1917",
    textAlign:"center",
  },
  submitButtonFullwodth:{
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    "@media (max-width:484px)": {
      marginTop: "20px"
    },
  },
  cancelButtonFullwidth: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#94A3B8',
    borderRadius: '8px',
    border: "1px solid #94A3B8",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
  },
  billingHeader:{
    fontSize: 24,
    fontWeight: 700,
    color: "#000",
  },
  closeIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  resendButton:{
    width: "35%",
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    margin:"0px 13px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#94A3B8',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#94A3B8',
        color: '#ffffff',
      },
    },
  },
  timer:{
    fontSize: 20,
    fontWeight: 500,
    color: "#334155",
    marginBottom: "20px",
  },
  modalContext:{
    fontSize: 16,
    fontWeight: 400,
    marginBottom: "20px",
    color: "#334155",
  },
  modalAddrerssCardContext:{
    fontSize: 16,
    fontWeight: 400,
    marginBottom: "40px",
    color: "#334155",
  },
  modalHeaderTitle:{
    fontSize: 24,
    fontWeight: 700,
    marginBottom: "20px",
    color: "#0F172A",
  },
  modal:{
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"40px",
  },
  modalAddressCard:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"70px",
  },
  modalInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "700px",
    transform: "Translate(-50%, -50%)",
  },
  submitButton:{
    width: "25%",
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    margin:"0px 13px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    "@media (max-width:484px)": {
      marginTop: "20px"
    },
  },
  cancelButton: {
    width: "25%",
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#94A3B8',
    borderRadius: '8px',
    border: "1px solid #94A3B8",
    padding: '10px 16px',
    margin:"0px 13px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
  },
  buttonBox:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px 25px 0px",
    "@media (max-width:484px)": {
      flexDirection: "column"
    },
  },
  iconColor: {
    color: '#94A3B8',
  },
  grid:{
    position: 'relative' as 'relative',
    marginLeft: "0px !important",
    marginTop: "0px !important",
    width: "100% !important",
  },
  blurred: {
    pointerEvents: 'none',
  } as React.CSSProperties,
  overlay: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(236, 250, 255, 0.6)',
    zIndex: 1,
  },
  overlay1: {
    pointerEvents: 'none',
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  gridStyles:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
    padding: "0px 25px !important",
  },
  gridPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
    padding: "10px 200px 0px !important",
    '@media (max-width: 1600px)': {
      padding: '10px 130px 0px !important',
    },
    '@media (max-width: 1450px)': {
      padding: '10px 120px 0px !important',
    },
    '@media (max-width: 960px)': {
      padding: '10px 100px 0px !important',
    },
    '@media (max-width: 600px)': {
      padding: '10px 50px 0px !important',
    },
    '@media (max-width: 360px)': {
      padding: '10px 20px 0px !important',
    },
  },
  gridInnerPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
  },
  gridButtonPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
    margin: "40px 0 !important",
  },
  gridInnerPaddingLeft:{
    padding: "0px !important",
    paddingLeft: "10px !important",
  },
  gridInnerPaddingRight:{
    padding: "0px !important",
    paddingRight: "10px !important",
  },
  formikBox:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updateprofileFormControl:{
    width: '100%',
    marginBottom: '20px',
  },
  cardDetailsFormControl:{
    width: '100%',
    marginBottom: '20px',
  },
  labelTextUpdateProfie:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  fieldStyle:{
    backgroundColor: "#fff",
    '& .MuiFormHelperText-root': {
      margin: 0,
      backgroundColor: "#ECFAFF",
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft : "5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "#F1F5F9",
      borderColor: "#CBD5E1",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  container: {
    padding: "45px 40px",
    borderRadius: "8px", 
    "@media (min-width:900px)": {
      height: "100%",
    },
  },
  innerContainer: {
    backgroundColor: "#ECFAFF",
    height:'100%',
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0px 50px",
    "@media (max-width:676px)": {
      padding: "30px 30px 50px",
    },
  },
  tabRoot: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding:'20px 40px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '17px',
    backgroundColor: '#fff',
    '&.Mui-selected': {
      backgroundColor: '#1799CB',
      color: '#ffffff',
      borderRadius: '25px',
    },
    '&:not(.Mui-selected)': {
      backgroundColor: '#fff',
      color: '#94A3B8',
    },
    minWidth: '100px',
  },
  firstTab: {
    borderRadius: '25px 0 0 25px',
  },
  lastTab: {
    borderRadius: '0 25px 25px 0',
  },
  updateProfileForm:{
    width: '100%',
    padding: '0px 300px',
    "@media (max-width:1439px)": {
      padding: '0px 200px !important',
    },
    "@media (max-width:1099px)": {
      padding: '0px 100px !important',
    },
    "@media (max-width:699px)": {
      padding: '0px 50px !important',
    },
    "@media (max-width:559px)": {
      padding: '0px 30px !important',
    },
  },
};
// Customizable Area End
