import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getErrorAndHelperText } from "../../../components/src/Utility";
import { imgCheckmark, imgClose } from "./assets";

interface FormError {
  [key: string]: string;
}
interface FormErrorTouched {
  [key: string]: boolean;
}
// Customizable Area End

import TakeHelpControllerWeb, {
  Props,
  configJSON,
} from "./TakeHelpController.web";

export default class TakeHelp extends TakeHelpControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: false,
  };

  validationSchemaForAddress = Yup.object({
    address: Yup.string().required('Address is required'),
    city: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "City Name should only contain letters")
    .required('City Name is required'),
    state: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "State Name should only contain letters")
    .required('State Name is required'),
    zip: Yup.string()
    .matches(/^\d+$/, 'Zip Code must contain only numbers')
    .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits")
    .required('ZipCode is required'),
    country: Yup.string()
    .matches(/^[a-zA-Z ]+$/, "Country Name should only contain letters")
    .required('Country Name is required'),
  });

  getInitialValuesForAddress() {
    return {
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isContactUsSuccessfullyModal } = this.state;
    const initialValuesForAddress = this.getInitialValuesForAddress();
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.container}>
          <Box sx={styles.innerContainer}>
            <Box sx={styles.contactUsBox}>
              <Formik
                key={`${initialValuesForAddress.address}-${initialValuesForAddress.city}-${initialValuesForAddress.country}-${initialValuesForAddress.state}-${initialValuesForAddress.zip}`}
                initialValues={initialValuesForAddress}
                validationSchema={this.validationSchemaForAddress}
                onSubmit={() => this.handleContactUsSuccessfullyModalOpen()}
              >
                {({ errors, touched, isValid }) => (
                  <Form>
                    <Grid sx={styles.gridInnerPadding} container>
                      <Grid item xs={12} sx={styles.gridInnerPadding}>
                        <FormControl sx={styles.addressDetailsFormControl}>
                          <FormLabel sx={styles.addressDetailsLabelText}>
                            Address
                          </FormLabel>
                          <Field
                            as={TextField}
                            name="address"
                            variant="outlined"
                            type="text"
                            placeholder="Enter Address"
                            fullWidth
                            sx={styles.fieldStyle}
                            error={
                              getErrorAndHelperText(
                                "address",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              getErrorAndHelperText(
                                "address",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={styles.gridInnerPadding}>
                        <FormControl sx={styles.addressDetailsFormControl}>
                          <FormLabel sx={styles.addressDetailsLabelText}>
                            City
                          </FormLabel>
                          <Field
                            as={TextField}
                            name="city"
                            variant="outlined"
                            type="text"
                            placeholder="Enter City Name"
                            fullWidth
                            sx={styles.fieldStyle}
                            error={
                              getErrorAndHelperText(
                                "city",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              getErrorAndHelperText(
                                "city",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sx={styles.gridInnerPaddingRight}>
                        <FormControl sx={styles.addressDetailsFormControl}>
                          <FormLabel sx={styles.addressDetailsLabelText}>
                            State
                          </FormLabel>
                          <Field
                            as={TextField}
                            name="state"
                            variant="outlined"
                            type="text"
                            placeholder="Enter State Name"
                            fullWidth
                            sx={styles.fieldStyle}
                            error={
                              getErrorAndHelperText(
                                "state",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              getErrorAndHelperText(
                                "state",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sx={styles.gridInnerPaddingLeft}>
                        <FormControl sx={styles.addressDetailsFormControl}>
                          <FormLabel sx={styles.addressDetailsLabelText}>
                            ZIP
                          </FormLabel>
                          <Field
                            as={TextField}
                            name="zip"
                            variant="outlined"
                            type="text"
                            placeholder="Enter Zip Code"
                            fullWidth
                            sx={styles.fieldStyle}
                            error={
                              getErrorAndHelperText(
                                "zip",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              getErrorAndHelperText(
                                "zip",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={styles.gridInnerPadding}>
                        <FormControl sx={styles.addressDetailsFormControl}>
                          <FormLabel sx={styles.addressDetailsLabelText}>
                            Country
                          </FormLabel>
                          <Field
                            as={TextField}
                            name="country"
                            variant="outlined"
                            type="text"
                            placeholder="Enter Country Name"
                            fullWidth
                            sx={styles.fieldStyle}
                            error={
                              getErrorAndHelperText(
                                "country",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                            helperText={
                              getErrorAndHelperText(
                                "country",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={styles.gridInnerPadding}>
                        <Button
                          variant="contained"
                          fullWidth
                          type="submit"
                          sx={styles.submitButtonFullwodth}
                          data-test-id="edit-contactus-save"
                          disabled={!isValid}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        <Modal
          open={isContactUsSuccessfullyModal}
          onClose={this.handleContactUsSuccessfullyModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalContactUsInner}>
            <Box display={"flex"} justifyContent={"end"} marginBottom={"10px"}>
              <img
                src={imgClose}
                style={styles.closeDeleteIcon}
                data-test-id="contactus-successfully-modal-close"
                onClick={() => this.handleContactUsSuccessfullyModalClose()}
              />
            </Box>
            <Box sx={styles.modalContactUsSuccessfully}>
              <Box marginBottom={"50px"}>
                <img src={imgCheckmark} />
              </Box>
              <Typography sx={styles.contactUsSuccessfullyText}>
                Thank you!
              </Typography>
              <Typography sx={styles.contactUsSuccessfullyText}>
                Your submission has been sent.
              </Typography>
              <Button
                variant="contained"
                type="submit"
                sx={styles.okayButton}
                data-test-id="contactus-okay-modal-close"
                onClick={() => this.handleContactUsSuccessfullyModalClose()}
              >
                {configJSON.okay}
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  okayButton:{
    cursor: 'pointer',
    width:"25%",
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    marginTop: "30px"
  },
  modalContactUsInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "800px",
    transform: "Translate(-50%, -50%)",
  },
  modalContactUsSuccessfully:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"90px 140px",
  },
  closeDeleteIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  contactUsSuccessfullyText:{
    fontSize: "30px",
    fontWeight: "700",
    color: "#1C1917",
    textAlign:"center",
  },
  submitButtonFullwodth:{
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    marginTop: "30px"
  },
  contactUsBox:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding: '50px 600px',
    "@media (max-width:1599px)": {
      padding: '50px 450px !important',
    },
    "@media (max-width:1439px)": {
      padding: '50px 400px !important',
    },
    "@media (max-width:1199px)": {
      padding: '50px 200px !important',
    },
    "@media (max-width:1099px)": {
      padding: '50px 100px !important',
    },
    "@media (max-width:699px)": {
      padding: '50px 50px !important',
    },
    "@media (max-width:559px)": {
      padding: '30px 30px !important',
    },
  },
  container: {
    padding: "45px 40px",
    borderRadius: "8px", 
    "@media (min-width:900px)": {
      height: "100%",
    },
  },
  innerContainer: {
    borderRadius: "8px", 
    backgroundColor: "#ECFAFF",
    height:'100%',
    display:"flex",
  },
  gridInnerPadding:{
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
  },
  addressDetailsFormControl:{
    width: '100%',
    marginBottom: '20px',
  },
  addressDetailsLabelText:{
    fontWeight: '700',
    fontSize:"16px",
    lineHeight:"24px",
    color: "#0F172A",
    marginBottom: '5px',
  },
  fieldStyle:{
    backgroundColor: "#fff",
    '& .MuiFormHelperText-root': {
      margin: 0,
      backgroundColor: "#ECFAFF",
    },
    '& .MuiInputBase-input': {
      color: '#94A3B8',
      paddingLeft : "5px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CBD5E1',
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "#F1F5F9",
      borderColor: "#CBD5E1",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  gridInnerPaddingLeft:{
    padding: "0px !important",
    paddingLeft: "10px !important",
  },
  gridInnerPaddingRight:{
    padding: "0px !important",
    paddingRight: "10px !important",
  },
};
// Customizable Area End
