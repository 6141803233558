import React from "react";

// Customizable Area Start
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { imgArrowForward, imgCancel, imgCheck, imgCheckmark, imgClose, imgThreeDot } from "./assets";
// Customizable Area End

import SubscriptionBillingControllerWeb, {
  Props,
  configJSON,
} from "./SubscriptionBillingController.web";

export default class SubscriptionBilling extends SubscriptionBillingControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPriceDetail = () => {
    return (
      <Box padding={"24px"}>
        <Box display={"flex"} flexDirection={"column"} marginBottom={"23px"}>
          <Box display={"flex"} alignItems={"center"} marginBottom={"8px"}>
            <img src={imgCancel} />
            <Typography
              sx={{
                ...styles.mainTextDescription,
                fontSize: "14px",
              }}
            >
              Access toYear Over Year Comparison.
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <img src={imgCancel} />
            <Typography
              sx={{
                ...styles.mainTextDescription,
                fontSize: "14px",
              }}
            >
              Access to Similar CPT Codes.
            </Typography>
          </Box>
        </Box>
        <Box padding={"0px 20px"}>
          <Button variant="outlined" sx={styles.buyButton} fullWidth>
            Buy
          </Button>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      isDeleteModal,
      isLogoutModal,
      isDeleteSuccessfullyModal,
      isLogoutSuccessfullyModal,
      isCancelModal,
      isEditModal,
      isRevokeModal,
      anchorEl,
      plans,
    } = this.state;
    const open = Boolean(anchorEl);
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box sx={styles.subscriptionBillingBox}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={"20px"}
          >
            <Typography sx={styles.planHeaderText}>
              {configJSON.premium}
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <img
                src={imgThreeDot}
                data-test-id="img-three-dot"
                style={styles.threeDot}
                onClick={this.handleClick}
              />
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={open}
              data-test-id="menu-container"
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={styles.menuStyles}
            >
              <MenuItem
                sx={styles.menuItemText}
                data-test-id="edit-modal-open"
                onClick={() => this.handleEditModalOpen()}
              >
                Edit
              </MenuItem>
              <Divider sx={styles.dividerMenuItem} />
              <MenuItem
                sx={styles.menuItemText}
                data-test-id="cancel-modal-open"
                onClick={() => this.handleOpenCancelModal()}
              >
                Cancel
              </MenuItem>
            </Menu>
            <Modal
              open={isEditModal}
              onClose={this.handleEditModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalEditInner}>
                <Box sx={styles.editModalInner}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={"30%"}
                    sx={styles.boxBorder}
                  >
                    <Box padding={"24px"}>
                      <Typography sx={styles.freePlanText}>
                        {plans[0]?.name}
                      </Typography>
                      <Box display={"flex"} marginBottom={"10px"}>
                        <Typography sx={styles.planPriceModal}>
                          {plans[0]?.price}
                        </Typography>
                        <Typography sx={styles.planPriceUnitModal}>
                          /month
                        </Typography>
                      </Box>
                      <Typography sx={styles.planPriceDescription}>
                        Basic feature for up to{" "}
                        <span style={{ color: "black" }}>
                          {plans[0]?.cpt_code} CPT Code
                        </span>
                      </Typography>
                    </Box>
                    <Divider style={styles.dividerColor} />
                    {this.getPriceDetail()}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={"30%"}
                    sx={styles.boxBorder}
                  >
                    <Box padding={"24px"}>
                      <Typography sx={styles.freePlanText}>
                        {plans[1]?.name}
                      </Typography>
                      <Box display={"flex"} marginBottom={"10px"}>
                        <Typography sx={styles.planPriceModal}>
                          {plans[1]?.price}
                        </Typography>
                        <Typography sx={styles.planPriceUnitModal}>
                          /month
                        </Typography>
                      </Box>
                      <Typography sx={styles.planPriceDescription}>
                        Basic feature for up to{" "}
                        <span style={{ color: "black" }}>
                          {plans[1]?.cpt_code} CPT Code
                        </span>
                      </Typography>
                    </Box>
                    <Divider style={styles.dividerColor} />
                    {this.getPriceDetail()}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={"30%"}
                    sx={{ ...styles.boxBorder, backgroundColor: "#1E293B" }}
                  >
                    <Box padding={"24px"}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginBottom={"17px"}
                      >
                        <Typography
                          sx={{
                            ...styles.freePlanText,
                            color: "#F69544",
                            marginBottom: 0,
                          }}
                        >
                          {plans[2]?.name}
                        </Typography>
                        <Typography sx={styles.activePlan}>
                          Current Plan
                        </Typography>
                      </Box>
                      <Box display={"flex"} marginBottom={"10px"}>
                        <Typography
                          sx={{ ...styles.planPriceModal, color: "white" }}
                        >
                          {plans[2]?.price}
                        </Typography>
                        <Typography
                          sx={{ ...styles.planPriceModal, color: "white" }}
                        >
                          /month
                        </Typography>
                      </Box>
                      <Typography sx={styles.planPriceDescription}>
                        Up to{" "}
                        <span style={{ color: "White" }}>
                          Unlimited CPT Code
                        </span>
                      </Typography>
                    </Box>
                    <Divider style={styles.dividerColor} />
                    <Box padding={"24px"}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        marginBottom={"23px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          marginBottom={"8px"}
                        >
                          <img src={imgCheck} />
                          <Typography
                            sx={{
                              ...styles.mainTextDescription,
                              fontSize: "14px",
                              color: "white",
                            }}
                          >
                            Access toYear Over Year Comparison.
                          </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                          <img src={imgCheck} />
                          <Typography
                            sx={{
                              ...styles.mainTextDescription,
                              fontSize: "14px",
                              color: "white",
                            }}
                          >
                            Access to Similar CPT Codes.
                          </Typography>
                        </Box>
                      </Box>
                      <Box padding={"0px 20px"}>
                        <Button
                          variant="outlined"
                          sx={styles.revokeButton}
                          fullWidth
                          data-test-id="revoke-modal-open"
                          onClick={() => this.handleRevokeModalOpen()}
                        >
                          Revoke
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={isRevokeModal}
              onClose={this.handleRevokeModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalDeleteInner}>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  marginBottom={"10px"}
                >
                  <img
                    src={imgClose}
                    style={styles.closeDeleteIcon}
                    data-test-id="revoke-modal-close"
                    onClick={() => this.handleRevokeModalClose()}
                  />
                </Box>
                <Box sx={styles.modalDelete}>
                  <Typography sx={styles.modalDeleteContext}>
                    Are You Sure You Want To Revoke <b>Premium Plan</b>
                  </Typography>
                  <Box display={"flex"} width={"60%"}>
                    <Button
                      variant="contained"
                      sx={styles.cancelDeleteButtonFullwidth}
                      fullWidth
                      data-test-id="revoke-modal-close-cancel"
                      onClick={() => this.handleRevokeModalClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      data-test-id="revoke-modal-submit"
                      sx={styles.submitDeleteButtonFullwodth}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={isCancelModal}
              onClose={this.handleCancelModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalDeleteInner}>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  marginBottom={"10px"}
                >
                  <img
                    src={imgClose}
                    style={styles.closeDeleteIcon}
                    data-test-id="cancel-modal-close"
                    onClick={() => this.handleCancelModalClose()}
                  />
                </Box>
                <Box sx={styles.modalDelete}>
                  <Typography sx={styles.modalDeleteContext}>
                    Are You Sure You Want To Cancel Your <b>Current Plan</b>
                  </Typography>
                  <Box display={"flex"} width={"60%"}>
                    <Button
                      variant="contained"
                      sx={styles.cancelDeleteButtonFullwidth}
                      fullWidth
                      data-test-id="cancel-modal-close-cancel"
                      onClick={() => this.handleCancelModalClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      data-test-id="cancel-modal-submit"
                      sx={styles.submitDeleteButtonFullwodth}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
          <Box display={"flex"} alignItems={"center"} marginBottom={"8px"}>
            <img src={imgCheck} height={"16px"} width={"16px"} />
            <Typography sx={styles.mainTextDescription}>
              Access toYear Over Year Comparison.
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={"20px"}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Box display={"flex"} alignItems={"center"} marginBottom={"8px"}>
                <img src={imgCheck} height={"16px"} width={"16px"} />
                <Typography sx={styles.mainTextDescription}>
                  Access to Similar CPT Codes.
                </Typography>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <img src={imgCheck} height={"16px"} width={"16px"} />
                <Typography sx={styles.mainTextDescription}>
                  Up to Unlimited CPT Code
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.planPriceContainer}>
              <Typography sx={styles.planPrice}>$699</Typography>
              <Typography sx={styles.planPriceUnit}>/month</Typography>
            </Box>
          </Box>
          <Divider sx={styles.dividerColor} />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"40px 0px"}
          >
            <Typography
              sx={styles.deleteAccountText}
              data-test-id="delete-account-text"
              onClick={() => this.handleOpenDeleteModal()}
            >
              Delete Account
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              marginRight={"8px"}
            >
              <img
                src={imgArrowForward}
                data-test-id="delete-account-icon"
                onClick={() => this.handleOpenDeleteModal()}
              />
            </Box>
            <Modal
              open={isDeleteModal}
              onClose={this.handleDeleteModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalDeleteInner}>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  marginBottom={"10px"}
                >
                  <img
                    src={imgClose}
                    style={styles.closeDeleteIcon}
                    data-test-id="delete-modal-close"
                    onClick={() => this.handleDeleteModalClose()}
                  />
                </Box>
                <Box sx={styles.modalDelete}>
                  <Typography sx={styles.modalDeleteContext}>
                    Are You Sure You Want To <b>Delete Your Account</b>{" "}
                    Permanently?
                  </Typography>
                  <Box display={"flex"} width={"60%"}>
                    <Button
                      variant="contained"
                      sx={styles.cancelDeleteButtonFullwidth}
                      fullWidth
                      data-test-id="delete-modal-close-cancel"
                      onClick={() => this.handleDeleteModalClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-test-id="delete-account-submit"
                      variant="contained"
                      fullWidth
                      sx={styles.submitDeleteButtonFullwodth}
                      onClick={() => this.deleteAccount()}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={isDeleteSuccessfullyModal}
              onClose={this.handleDeleteSuccessfullyModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalDeleteInner}>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  marginBottom={"10px"}
                >
                  <img
                    src={imgClose}
                    style={styles.closeDeleteIcon}
                    data-test-id="delete-successfully-modal-close"
                    onClick={() => this.handleDeleteSuccessfullyModalClose()}
                  />
                </Box>
                <Box sx={styles.modalDeleteSuccessfully}>
                  <img src={imgCheckmark} />
                  <Typography sx={styles.modalDeleteSuccessfullyContext}>
                    Your Account has been Deleted.
                  </Typography>
                </Box>
              </Box>
            </Modal>
          </Box>
          <Divider sx={styles.dividerColor} />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"40px 0px"}
          >
            <Typography
              sx={styles.logoutAccountText}
              data-test-id="logout-account-text"
              onClick={() => this.handleOpenLogoutModal()}
            >
              Logout
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              marginRight={"8px"}
            >
              <img
                src={imgArrowForward}
                data-test-id="logout-account-icon"
                onClick={() => this.handleOpenLogoutModal()}
              />
            </Box>
            <Modal
              open={isLogoutModal}
              onClose={this.handleLogoutModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalDeleteInner}>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  marginBottom={"10px"}
                >
                  <img
                    src={imgClose}
                    style={styles.closeDeleteIcon}
                    data-test-id="logout-modal-close"
                    onClick={() => this.handleLogoutModalClose()}
                  />
                </Box>
                <Box sx={styles.modalDelete}>
                  <Typography sx={styles.modalDeleteContext}>
                    Are You Sure You Want To <b>Logout?</b>
                  </Typography>
                  <Box display={"flex"} width={"60%"}>
                    <Button
                      variant="contained"
                      sx={styles.cancelDeleteButtonFullwidth}
                      fullWidth
                      data-test-id="logout-modal-close-cancel"
                      onClick={() => this.handleLogoutModalClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      data-test-id="logout-modal-submit"
                      onClick={() => this.handleLogoutAccount()}
                      sx={styles.submitDeleteButtonFullwodth}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={isLogoutSuccessfullyModal}
              onClose={this.handleLogoutSuccessfullyModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles.modalDeleteInner}>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  marginBottom={"10px"}
                >
                  <img
                    src={imgClose}
                    style={styles.closeDeleteIcon}
                    data-test-id="logout-successfully-modal-close"
                    onClick={() => this.handleLogoutSuccessfullyModalClose()}
                  />
                </Box>
                <Box sx={styles.modalDeleteSuccessfully}>
                  <img src={imgCheckmark} />
                  <Typography sx={styles.modalDeleteSuccessfullyContext}>
                    You have been Logged Out
                  </Typography>
                </Box>
              </Box>
            </Modal>
          </Box>
          <Divider sx={styles.dividerColor} />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  activePlan:{
    fontSize:"16px",
    fontWeight:700,
    color:"#10B981"
  },
  revokeButton:{
    cursor: 'pointer',
    backgroundColor: '#1E293B',
    color: '#F87171',
    borderRadius: '8px',
    border: "1px solid #F87171",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1E293B',
    },
  },
  buyButton: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: 'black',
    borderRadius: '8px',
    border: "1px solid #94A3B8",
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  planPriceDescription:{
    fontSize: "16px",
    fontWeight: 500,
    color: "#94A3B8",
  },
  boxBorder:{
    borderRadius: "8px",
    border: "1px solid #94A3B8"
  },
  freePlanText:{
    fontSize: "30px",
    fontWeight: 500,
    color: "#94A3B8",
    marginBottom: "16px",
  },
  editModalInner:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: "60px"
  },
  modalEditInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1284px",
    transform: "Translate(-50%, -50%)",
  },
  planPriceContainer: {
    display: "flex",
    alignitems: "center",
    justifyContent: "center",
  },
  planPrice: {
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "56px",
    color: "#334155",
  },
  planPriceUnit: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1E293B",
    marginTop: "18px",
  },
  planPriceModal: {
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "56px",
    color: "#0F172A",
  },
  planPriceUnitModal: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#0F172A",
    marginTop: "18px",
  },
  mainTextDescription:{
    fontSize: "16px",
    marginLeft: "7px",
    marginTop: "5px",
    lineHeight:"18px",
    color: "#9795B5",
    textAlign: "center"
  },
  menuItemText:{
    fontSize: "16px",
    fontWeight: "400",
    color: "#9795B5",
  },
  menuStyles:{
    '& .MuiPaper-root': {
      boxShadow: 'none',
      marginLeft: "5px",
      borderRadius: "8px",
    },
    '& .MuiList-root': {
      padding: "0px !important",
    },
    '& .MuiMenuItem-root': {
      paddingTop: "15px !important",
      paddingLeft: "15px !important",
      paddingBottom: "15px !important",
      paddingRight: "40px !important",
    },
  },
  threeDot:{
    width: "30px",
    height: "30px",
    cursor: 'pointer',
    backgroundColor: "white",
  },
  planHeaderText:{
    fontSize: "25px",
    fontWeight: "700",
    color: "#000",
  },
  modalDeleteSuccessfullyContext:{
    fontSize: "25px",
    fontWeight: "700",
    color: "#1C1917",
    marginTop: "20px",
  },
  submitDeleteButtonFullwodth:{
    cursor: 'pointer',
    backgroundColor: '#1799CB',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    marginLeft: "10px",
    '&:hover': {
      backgroundColor: '#1799CB',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
    "@media (max-width:484px)": {
      marginTop: "20px"
    },
  },
  cancelDeleteButtonFullwidth: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#94A3B8',
    borderRadius: '8px',
    border: "1px solid #94A3B8",
    padding: '10px 16px',
    marginRight: "10px",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    "@media (max-width:811px)": {
      width: "100%",
    },
  },
  modalDeleteContext:{
    fontSize: 16,
    fontWeight: 400,
    marginBottom: "40px",
    color: "#334155",
  },
  modalDelete:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"70px",
  },
  modalDeleteSuccessfully:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding:"90px 140px",
  },
  closeDeleteIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  modalDeleteInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "700px",
    transform: "Translate(-50%, -50%)",
  },
  deleteAccountText:{
    fontSize: '16px',
    fontWeight: '400',
    color: '#1E293B',
    marginLeft: '8px',
  },
  logoutAccountText:{
    fontSize: '16px',
    fontWeight: '400',
    color: 'red',
    marginLeft: '8px',
  },
  dividerColor:{
    backgroundColor: '#D4D2E3',
  },
  dividerMenuItem:{
    backgroundColor: '#D4D2E3',
    margin: '0px !important',
  },
  subscriptionBillingBox:{
    width: '100%',
    padding: '0px 300px 200px',
    "@media (max-width:1439px)": {
      padding: '0px 200px 100px !important',
    },
    "@media (max-width:1099px)": {
      padding: '0px 100px 50px !important',
    },
    "@media (max-width:699px)": {
      padding: '0px 50px 25px !important',
    },
    "@media (max-width:559px)": {
      padding: '0px 30px 15px !important',
    },
  },
};
// Customizable Area End
