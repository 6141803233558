Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.deleteAccountEndPoint = 'account_block/accounts/delete_account';
exports.premium = "Premium";
exports.getPlanDataEndPoint = "bx_block_plan/plans";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End