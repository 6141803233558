import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

interface PasswordValues{
  password: string;
  confirmPassword: string;
}

interface ResponseJson {
  error?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string | null;
  showPassword:boolean;
  showConfirmPassword:boolean;
  confirmPasswordError:string;
  fieldBlurred: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ResetPasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiResetPasswordCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      token: "",
      showPassword:false,
      showConfirmPassword: false,
      confirmPasswordError: "",
      fieldBlurred: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        responseJson.error &&
        apiRequestCallId === this.apiResetPasswordCallId
      ) {
        this.handleApiError(responseJson);
      }

      if (
        responseJson &&
        !responseJson.error &&
        apiRequestCallId === this.apiResetPasswordCallId
      ) {
        this.handleApiSuccess();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    const searchParams = new URLSearchParams(this.props.navigation.history.location.search);
    const token = searchParams.get('token');
    this.setState({ token });
    return Promise.resolve();
  }

  handleApiError = (responseJson: ResponseJson) => {
    if (responseJson.error) {
      this.setState({ confirmPasswordError: "Please enter the password again" });
    }
  }

  handleApiSuccess = () => {
    this.handleLoginNavigation()
  }

  handleLoginNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleSubmit = (values: PasswordValues) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const httpBody = {
      password: values.password,
      confirm_password: values.confirmPassword,
      check_login: values.password,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBlur = (password : string) => {
    if(password){
      this.setState({ fieldBlurred: true });
    }
    else{
      this.setState({ fieldBlurred: false });
    }
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  };
  // Customizable Area End
}
