export const imgLandingPage = require("../assets/landing_page.png");
export const imgGroup = require("../assets/group_.png");
export const imgRight = require("../assets/image_right_click.png");
export const imgClose = require("../assets/image_close_click.png");
export const imgVideo = require("../assets/image_video.png");
export const imgStar = require("../assets/image_star.png");
export const imgLock = require("../assets/image_lock.png");
export const imgEarth = require("../assets/image_earth.png");
export const imgDoubleQuote = require("../assets/image_doublequote.png");
export const imgInstagram = require("../assets/image_instagram.png");
export const imgTwitter = require("../assets/image_twitter.png");
export const imgLinkdln = require("../assets/image_linkdln.png");
export const imgFacebook = require("../assets/image_facebook.png");
export const imgYoutube = require("../assets/image_youtube.png");
export const imgPhone = require("../assets/image_phone.png");
export const imgLocation = require("../assets/image_location.png");
export const imgMail = require("../assets/image_mail.png");
export const imgLogo = require("../assets/image_logo.png");
export const imgInfo = require("../assets/image_info.png");
export const imgCloseModal = require("../assets/image_close.png");
export const imgArrowForward = require("../assets/image_arrow_forward.png");
export const imgCloseButton = require("../assets/image_close_button.png");
export const imgPlusButton = require("../assets/image_plus_button.png");
