Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name";
exports.lastName = "Last Name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.loginAlt = "image_login";
exports.labelTitle = "Sign up";
exports.zipCode = "Zip Code";
exports.phoneNumber = "Phone No.";
exports.createAccount = "Create Account";
exports.alreadyHaveAnAccount = "Already have an account?";
exports.login = "Log in";
exports.signUpAPiEndPoint = "account_block/accounts";
exports.setPasswordApiEndPoint = "account_block/accounts/set_password";

exports.validationApiMethodType = "GET";
exports.continueButton = "Continue";
exports.getPlanDataEndPoint = "bx_block_plan/plans";
exports.getSignupVideoEndPoint = "bx_block_profile/profiles/signup_video";
exports.stripePaymentEndPoint = "bx_block_stripe_integration/payment_intents/create_checkout_session";
exports.addCardAndAddressDetailsEndPoint = "bx_block_settings/settings/create_stripe_detail";
exports.publishment = "pk_test_51PRC7a02h0HQpyC0en3A953qYtevw7gxNcFlUeipDkE1JtBHjom8K2izTgU5DF0mVTmzRS4S551IRgo7i1pOW4Xc00Fl9PFB7n";
// Customizable Area End
