Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.rorem = "Rorem Ipsum";
exports.httpGetMethod = "GET";
exports.getTermsdataEndPoint = "bx_block_terms_and_conditions/terms_and_conditions/";
exports.zipcodeText = "Enter Zip Code";
exports.cptcodeText = "Enter a CPT Code or Keyword";
exports.zipCodePlaceholder = "Enter ZIP Code or City , State";
exports.cptCodePlaceholder = "CPT Code";
exports.continueBtnText = "Continue";
exports.checkCptCode = "Check CPT Code";
exports.tooltipText = `The code used to identify this service is called a "CPT code." For the most accurate estimate, please ask your doctor for the CPT code or locate it on your bill.`;
exports.zipCodeError = "Zip Code must be exactly 5 digits";
exports.zipCodeRequiredError = "ZIP code is required";
exports.cptCodeRequiredError = "CPT code is required";
exports.costRelatedTo = "Cost of Care Related to";
exports.getCPTCodedataEndPoint = "bx_block_custom_form/cpt_codes/search_cpt_code";
exports.getCPTCodeEndPoint = "bx_block_custom_form/cpt_codes/cptcode_average_percentage";
exports.fiftyPercentile = "50th percentiles";


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End