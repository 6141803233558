import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";

interface PasswordValues{
  password: string;
  confirmPassword: string;
}

interface ResponseJson {
  error?: string;
}

interface SubscriptionPlan {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string | null;
  created_at: string;
  updated_at: string;
  cpt_code: number;
  plan1: boolean;
  plan2: boolean;
  features: string;
}

interface ResponseSubscriptionPlan{
  plans: SubscriptionPlan[]
}

interface MediaUrl {
  id: number;
  url: string;
  filename: string;
  content_type: string;
}

interface MediaResponse {
  media_url: MediaUrl;
  activated: boolean;
}

interface CardDetailsFormValues {
  cardholderName: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
  country: string;
}

interface StripeErrorResponse {
  code: string;
  doc_url: string;
  message: string;
  param: string; 
  request_log_url: string; 
  type: string;
}

interface AddressDetails {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string | null;
  showPassword:boolean;
  showConfirmPassword:boolean;
  confirmPasswordError:string;
  fieldBlurred: boolean;
  passwordSetSuccess:boolean;
  isImageVideoModalVisible: boolean;
  isPlaying: boolean;
  isHovered: boolean;
  isVideoEnded: boolean;
  isPlanModalVisible: boolean;
  isPlan1Hovered: boolean;
  isPlan2Hovered: boolean;
  isPlan3Hovered: boolean;
  isCardDetailsModal: boolean;
  planById: SubscriptionPlan | null;
  isPremiumPlanSelected: boolean;
  isAdressModal: boolean;
  isSkipForNowClicked: boolean;
  isVideoType: boolean;
  signupVideoUrlResponse: MediaResponse | null;
  freePlan: SubscriptionPlan | null;
  basicPlan: SubscriptionPlan | null;
  premiumPlan: SubscriptionPlan | null;
  cardNumberError: string;
  expirationError: string;
  cvvError: string;
  stripeToken: string;
  cardDetails: CardDetailsFormValues | null;
  addressDetails: AddressDetails | null;
  isPaymentDone: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreatePasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailSetPasswordCallId: string = "";
  apiGetPlanData: string = "";
  apiGetPlanDataById: string = "";
  apiGetSignupImageVideo: string = "";
  apiStripePayment: string = "";
  apiAddCardAndAddressDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      token: "",
      showPassword:false,
      showConfirmPassword: false,
      confirmPasswordError: "",
      fieldBlurred: false,
      passwordSetSuccess: false,
      isImageVideoModalVisible: false,
      isPlaying: false,
      isHovered: false,
      isVideoEnded: false,
      isPlanModalVisible: false,
      isPlan1Hovered: false,
      isPlan2Hovered: false,
      isPlan3Hovered: false,
      isCardDetailsModal: false,
      planById: null,
      isPremiumPlanSelected: false,
      isAdressModal: false,
      isSkipForNowClicked: false,
      isVideoType: false,
      signupVideoUrlResponse: null,
      freePlan: null,
      basicPlan: null,
      premiumPlan: null,
      cardNumberError: "",
      expirationError: "",
      cvvError: "",
      stripeToken: "",
      cardDetails: null,
      addressDetails: null,
      isPaymentDone: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiEmailSetPasswordCallId:
          if (responseJson.error) {
            this.handleError(responseJson);
          } else {
            this.handleSuccess();
          }
          break;
    
        case this.apiGetPlanData:
          this.handlePlanDataReturn(responseJson);
          break;
    
        case this.apiGetSignupImageVideo:
          this.setState({ 
            signupVideoUrlResponse: responseJson, 
            passwordSetSuccess: responseJson.activated,
            isVideoType: responseJson.media_url.content_type === "video/mp4"
          });
          break;
    
        case this.apiGetPlanDataById:
          this.setState({ 
            planById: responseJson.plans, 
            isPremiumPlanSelected: responseJson.plans.name === "premium" 
          });
          break;
          
        case this.apiStripePayment:
          if (!responseJson.error) {
            this.handleAddCardAndAddressDetails();
          }
          else{
            toast.error("An error occurred. Please try again later.")
          }
          break;

        case this.apiAddCardAndAddressDetails:
          if (!responseJson.error) {
            this.handleAddressModalClose();
            this.setState({ isPaymentDone: true });
          }
          break;
    
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    const searchParams = new URLSearchParams(this.props.navigation.history.location.search);
    const token = searchParams.get('token');
    this.setState({ token });
    this.getPlanData()
    this.getSignupVideoUrl(token);
    return Promise.resolve();
  }

  handleError = (responseJson: ResponseJson) => {
    if (responseJson.error) {
      this.setState({ confirmPasswordError: "Please enter the password again" });
    }
  }
  
  handlePlanDataReturn = (responseJson : ResponseSubscriptionPlan) => {
    const { plans } = responseJson;
    const freePlan = plans.find(plan => plan.name === 'free') || null;
    const basicPlan = plans.find(plan => plan.name === 'basic') || null;
    const premiumPlan = plans.find(plan => plan.name === 'premium') || null;

    this.setState({ 
      freePlan, 
      basicPlan, 
      premiumPlan, 
    });
  }

  handleSuccess = () => {
    this.setState({ 
      confirmPasswordError: "", 
      passwordSetSuccess: true, 
    });
  }

  handleSubmit = (values: PasswordValues) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const httpBody = {
      password: values.password,
      check_login: values.password,
      confirm_password: values.confirmPassword,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailSetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setPasswordApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBlur = (password : string) => {
    if(password){
      this.setState({ fieldBlurred: true });
    }
    else{
      this.setState({ fieldBlurred: false });
    }
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  };

  handleClickGetStartedButton = () => {
    this.setState({ isImageVideoModalVisible: true });
  }

  handleImageVideoModalClose = () => {
    this.setState({ isImageVideoModalVisible: false, isVideoEnded: false, isPlaying: false });
  }

  handleVideoClick = () => {
    this.setState(prevState => ({
      isPlaying: !prevState.isPlaying,
    }));
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  handleVideoEnded = () => {
    this.setState({ isVideoEnded: true, isPlaying: false }); 
  };

  getPlanData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlanDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSignupVideoUrl = (token : string | null) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSignupImageVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSignupVideoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleClickSeePricingButton = () => {
    this.handleImageVideoModalClose();
    this.setState({ isPlanModalVisible: true, isVideoEnded: false });
  }

  handleSeePricingModalClose = () => {
    this.setState({ isPlanModalVisible: false });
  }

  handlePlan1Hovered = () => {
    this.setState({ isPlan1Hovered: true });
  };

  handlePlan1Leave = () => {
    this.setState({ isPlan1Hovered: false });
  };

  handlePlan2Hovered = () => {
    this.setState({ isPlan2Hovered: true });
  };

  handlePlan2Leave = () => {
    this.setState({ isPlan2Hovered: false });
  };

  handlePlan3Hovered = () => {
    this.setState({ isPlan3Hovered: true });
  };

  handlePlan3Leave = () => {
    this.setState({ isPlan3Hovered: false });
  };

  handleClickContinueButton = (id : number | undefined) => {
    this.handleSeePricingModalClose();
    this.getPlanDataById(id);
    this.setState({ isCardDetailsModal: true });
  };

  getPlanDataById = (id : number | undefined) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPlanDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_plan/plans/${id}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCardDetailsModalClose = () => {
    this.setState({ isCardDetailsModal: false });
  };

  handleCardDetailsSubmit = (values : CardDetailsFormValues) => {
    const formattedValues = {
      ...values,
      cardNumber: values.cardNumber.replace(/\s+/g, ""),
    };
    this.createStripeToken(formattedValues);
  };

  createStripeToken = async (values: CardDetailsFormValues) => {
    const [expMonth, expYear] = values.expiration.split('/');
    const formData = new URLSearchParams();
    formData.append('card[number]', values.cardNumber);
    formData.append('card[exp_month]', expMonth);
    formData.append('card[exp_year]', expYear);
    formData.append('card[cvc]', values.cvv);
  
    await fetch('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${configJSON.publishment}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.handleStripeTokenGenerationError(data.error);
        } else {
          this.setState({ stripeToken: data.id, isAdressModal: true, cardDetails: values })
          this.handleCardDetailsModalClose();
        }
      });
  };

  handleStripeTokenGenerationError = (error: StripeErrorResponse) => {
    switch (error.code) {
      case 'incorrect_number':
        this.setState({ cardNumberError : error.message });
        break;
      case 'invalid_expiry_month':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_expiry_year':
        this.setState({ expirationError : error.message });
        break;
      case 'invalid_cvc':
        this.setState({ cvvError : error.message });
        break;
      default:
        break;
    }
  };

  onCardNumberChange = () => {
    this.setState({ cardNumberError: '' });
  };

  onExpirationChange = () => {
    this.setState({ expirationError: '' });
  };

  onCvvChange = () => {
    this.setState({ cvvError: '' });
  };

  handleAddressModalClose = () => {
    this.setState({ isAdressModal: false });
  };

  handleSkipButton = () => {
    this.setState({ isSkipForNowClicked: true, isPlanModalVisible: false });
  }

  handleAddressSubmit = (values : AddressDetails) => {
    this.setState({ addressDetails: values });
    this.handleStripePayment();
  };

  handleStripePayment = () => {
    const { planById, token, stripeToken } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const httpBody = {
      plan_id: `${planById?.id}`,
      data:{
        token_card: stripeToken
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStripePayment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripePaymentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddCardAndAddressDetails = () => {
    const { cardDetails, addressDetails, token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const httpBody = {
      cardholder_name: cardDetails?.cardholderName,
      card_number: cardDetails?.cardNumber,
      expiration_date: cardDetails?.expiration,
      cvv: cardDetails?.cvv,
      country1: cardDetails?.country,
      address_line: addressDetails?.address,
      city: addressDetails?.city,
      state: addressDetails?.state,
      zip: addressDetails?.zip,
      country2: addressDetails?.country
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddCardAndAddressDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCardAndAddressDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSkipModalClose = () => {
    this.setState({ isSkipForNowClicked: false });
  };

  handlePaymentDoneModalClose = () => {
    this.setState({ isPaymentDone: false });
  };

  handleLoginNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
