import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

interface ZipCPTCodeData{
  zipCode: string;
  cptCode: string;
}

interface ChartData {
  average: number;
  percentile_fifty: number;
  percentile_ninety: number;
}

interface ProcedureCostResponse {
  fifty_percentile_value: number;
  ninty_percentile_value: number;
  average: number;
  cpt_code_value: string;
  procedure_code_description: string;
  min_max_billed_charge: number[];
  chart_data: {
    [year: string]: ChartData;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  tabInnerValue: string;
  isTableModalOpen: boolean;
  token: string;
  cptCodeDataResponse: ProcedureCostResponse | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPagePaymentControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCptCodeData: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      tabValue: 0,
      tabInnerValue: "1",
      isTableModalOpen: false,
      token: "",
      cptCodeDataResponse: null,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        responseJson.error &&
        apiRequestCallId === this.apiGetCptCodeData
      ) {
        this.setState({ cptCodeDataResponse: null });
      }

      if (
        responseJson &&
        !responseJson.error &&
        apiRequestCallId === this.apiGetCptCodeData
      ) {
        this.setState({ cptCodeDataResponse: responseJson });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount(): Promise<void> {
    this.checkTokenFromLocalStorage();
    this.checkZipcodeAndCPTCodeFromLocalStorage();
    return Promise.resolve();
  }

  checkZipcodeAndCPTCodeFromLocalStorage = async () => {
    const selectedCptCodeData = await getStorageData("selectedCptCodeData",true);
    this.getCPTCodeData(selectedCptCodeData)
  }

  checkTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
  };

  getCPTCodeData = (selectedCptCodeData: ZipCPTCodeData) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCptCodeData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCPTCodeEndPoint}?zip_code=${selectedCptCodeData?.zipCode}&cpt_code=${selectedCptCodeData?.cptCode}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    this.setState({ tabValue: newValue });
  };

  handleInnerTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    this.setState({ tabInnerValue: newValue });
  };

  handleSimmiliarCptCodeButton = () => {
    this.setState({ isTableModalOpen: true });
  }

  handleModalClose = () => {
    this.setState({ isTableModalOpen: false });
  };

  getChartData = (chart_data: { [year: string]: ChartData }) => {
    const years = Object.keys(chart_data);
    const averageValues = years.map(year => chart_data[year].average);
    const percentileFiftyValues = years.map(year => chart_data[year].percentile_fifty);
    const percentileNinetyValues = years.map(year => chart_data[year].percentile_ninety);

    const minYear = Math.min(...years.map(year => +year));

    return [
      ['Year', 'Average', { role: 'annotation', type: 'string' }, '50th Percentile', '90th Percentile'],
      ...years.map((year, index) => [
        +year, 
        averageValues[index], 
        year == minYear.toString() ? 'Min year Min year Min year Min Year Min Year min' : null,
        percentileFiftyValues[index], 
        percentileNinetyValues[index]
      ]),
    ];
  };
  // Customizable Area End
}
