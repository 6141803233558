import React from "react";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
  Box,
  Tabs,
  Tab,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { imgCloseButton, imgCloseModal, imgPlusButton } from "./assets";
import { Chart } from "react-google-charts";
// Customizable Area End

import LandingPagePaymentControllerWeb, {
  Props,
  configJSON,
} from "./LandingPagePaymentController.web";

export default class LandingPagePayment extends LandingPagePaymentControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "NavigationMenu",
    isLandingPage: false,
  };

  renderIf = <P,>(condition: boolean, component: React.ReactElement<P>) => {
    return condition ? component : null;
  };

  tableData = [
    { 
      id: 1, 
      title: "CPT CODE- 11100", 
      description: "Transportaion, Medical Equipment and Supplies", 
      average: "$1,110", 
      "50thPercentile": "$111", 
      "90thPercentile": "$111", 
    },
    { 
      id: 2, 
      title: "CPT CODE- 11600", 
      description: "Infusion supplies not used with an external drug pump (per cassette or bag). Drugs are billed separately.", 
      average: "$5,770", 
      "50thPercentile": "$100", 
      "90thPercentile": "$100", 
    },
    { 
      id: 3, 
      title: "CPT CODE- 41177", 
      description: "Syringe with needles, small (1 cc)", 
      average: "$1,000", 
      "50thPercentile": "$100", 
      "90thPercentile": "$100", 
    },
    { 
      id: 4, 
      title: "CPT CODE- 51160", 
      description: "Sterile salt water or other flushing liquid, 10 ml", 
      average: "$1,000", 
      "50thPercentile": "$100", 
      "90thPercentile": "$100", 
    },
    { 
      id: 5, 
      title: "CPT CODE- 98195", 
      description: "Replacement battery for home blood sugar monitoring kit, alkaline", 
      average: "$1,000", 
      "50thPercentile": "$100", 
      "90thPercentile": "$100", 
    },
  ];
  
  moreInfoData= [
    {
      id:1,
      desciption:"Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    },
    {
      id:2,
      desciption:"Horem ipsum dolor sit amet consectetur",
    },
    {
      id:3,
      desciption:"Norem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id:4,
      desciption:"Horem ipsum dolor sit amet consectetur",
    },
  ]
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { tabValue, tabInnerValue, isTableModalOpen, cptCodeDataResponse } = this.state;
    const getTabStyles = ( isSelected:boolean ) => ({
      ...styles.tab,
      fontWeight: isSelected ? 600 : 500,
      borderBottom: isSelected ? "3px solid #1799CB" : "3px solid #E2E8F0"
    });
    return (
      <>
        <NavigationMenu {...this.screenProps} />
        <Box sx={styles.container}>
          <Box sx={styles.innerContainer}>
            {this.renderIf(
              tabValue === 0,
              <Box sx={styles.header}>
                <Box width={"50%"}>
                  <Typography variant="h2" sx={styles.title}>
                    {configJSON.costRelatedTo}
                  </Typography>
                  <Typography variant="h3" sx={styles.subTitle}>
                    Yorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis.
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h3" sx={styles.cityName}>
                    Union City, 11101
                  </Typography>
                  <Typography variant="h3" sx={styles.percentileText}>
                    *Estimates are based on the 50th and 90th percentiles.
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              sx={
                tabValue === 0
                  ? styles.contentBox
                  : styles.contentBoxForNextTabs
              }
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabChange}
                  aria-label="tabs example"
                  data-test-id="tabs"
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  <Tab
                    label="Local Cost"
                    sx={{ ...styles.tabRoot, ...styles.firstTab }}
                  />
                  <Tab label="Year Over Year Comparison" sx={styles.tabRoot} />
                  <Tab
                    label="More Information"
                    sx={{ ...styles.tabRoot, ...styles.lastTab }}
                  />
                </Tabs>
              </Box>
              {this.renderIf(
                tabValue === 0,
                <>
                  <TabContext value={tabInnerValue}>
                    <Box sx={styles.innerTab}>
                      <TabList
                        data-test-id="innerTabs"
                        onChange={this.handleInnerTabChange}
                      >
                        <Tab
                          label="In-Network Price"
                          value="1"
                          sx={getTabStyles(tabInnerValue === "1")}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Box sx={styles.tabPanel1}>
                        <TableContainer sx={styles.tableContainer}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                  }}
                                >
                                  Primary Medical Procedure
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    position: "relative",
                                    "&::after": {
                                      content: '""',
                                      position: "absolute",
                                      top: "50%",
                                      right: 0,
                                      height: "50%",
                                      borderRight: "1px solid #ccc",
                                      transform: "translateY(-50%)",
                                    },
                                  }}
                                  align="center"
                                >
                                  Average
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    position: "relative",
                                    "&::after": {
                                      content: '""',
                                      position: "absolute",
                                      top: "50%",
                                      right: 0,
                                      height: "50%",
                                      borderRight: "1px solid #ccc",
                                      transform: "translateY(-50%)",
                                    },
                                  }}
                                  align="center"
                                >
                                  {configJSON.fiftyPercentile}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...styles.tableHeadCell,
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                  }}
                                  align="center"
                                >
                                  90th percentiles
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  sx={{ ...styles.tableBodyCell, width: "50%" }}
                                >
                                  <Box component="span" display="block">
                                    CPT CODE-{" "}
                                    {cptCodeDataResponse?.cpt_code_value}
                                  </Box>
                                  <Box
                                    component="span"
                                    display="block"
                                    sx={styles.innerBoxText}
                                  >
                                    {
                                      cptCodeDataResponse?.procedure_code_description
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={styles.tableBodyCell}
                                  align="center"
                                >
                                  ${cptCodeDataResponse?.average}
                                </TableCell>
                                <TableCell
                                  sx={styles.tableBodyCell}
                                  align="center"
                                >
                                  ${cptCodeDataResponse?.fifty_percentile_value}
                                </TableCell>
                                <TableCell
                                  sx={styles.tableBodyCell}
                                  align="center"
                                >
                                  ${cptCodeDataResponse?.ninty_percentile_value}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        paddingTop={"20px"}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          sx={styles.simmiliarButton}
                          data-test-id="simmiliar-button"
                          onClick={() => this.handleSimmiliarCptCodeButton()}
                        >
                          View Similar CPT Codes
                        </Button>
                      </Box>
                      <Modal
                        open={isTableModalOpen}
                        onClose={this.handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={styles.modalInner}>
                          <Box
                            display={"flex"}
                            justifyContent={"end"}
                            marginBottom={"10px"}
                          >
                            <img
                              src={imgCloseModal}
                              style={styles.closeIcon}
                              data-test-id="close-icon"
                              onClick={() => this.handleModalClose()}
                            />
                          </Box>
                          <Box sx={styles.modal}>
                            <TableContainer sx={styles.tableContainer}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        ...styles.tableHeadCell,
                                        borderBottomLeftRadius: "8px",
                                        borderTopLeftRadius: "8px",
                                      }}
                                    >
                                      Primary Medical Procedure
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        ...styles.tableHeadCell,
                                        position: "relative",
                                        "&::after": {
                                          content: '""',
                                          position: "absolute",
                                          top: "50%",
                                          right: 0,
                                          height: "50%",
                                          borderRight: "1px solid #ccc",
                                          transform: "translateY(-50%)",
                                        },
                                      }}
                                    >
                                      Average
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        ...styles.tableHeadCell,
                                        position: "relative",
                                        "&::after": {
                                          content: '""',
                                          position: "absolute",
                                          top: "50%",
                                          right: 0,
                                          height: "50%",
                                          borderRight: "1px solid #ccc",
                                          transform: "translateY(-50%)",
                                        },
                                      }}
                                    >
                                      50th percentiles
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        ...styles.tableHeadCell,
                                        borderBottomRightRadius: "8px",
                                        borderTopRightRadius: "8px",
                                      }}
                                    >
                                      90th percentiles
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.tableData.map((row) => (
                                    <TableRow
                                      key={row.id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          ...styles.tableModalBodyCell,
                                          width: "45%",
                                        }}
                                      >
                                        <Box component="span" display="block">
                                          {row.title}
                                        </Box>
                                        <Box
                                          component="span"
                                          display="block"
                                          sx={styles.innerBoxTextModal}
                                        >
                                          {row.description}
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        sx={styles.tableModalBodyCell}
                                        align="center"
                                      >
                                        {row.average}
                                      </TableCell>
                                      <TableCell
                                        sx={styles.tableModalBodyCell}
                                        align="center"
                                      >
                                        {row["50thPercentile"]}
                                      </TableCell>
                                      <TableCell
                                        sx={styles.tableModalBodyCell}
                                        align="center"
                                      >
                                        {row["90thPercentile"]}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      </Modal>
                    </TabPanel>
                  </TabContext>
                </>
              )}
              {this.renderIf(
                tabValue === 1,
                <Box sx={styles.yearOverYearBox}>
                  {cptCodeDataResponse?.chart_data && (
                    <Chart
                      width={"100%"}
                      height={"450px"}
                      chartType="LineChart"
                      data={this.getChartData(cptCodeDataResponse.chart_data)}
                      options={{
                        legend: { position: "bottom" },
                        vAxis: {
                          gridlines: {
                            color: "transparent",
                            count: 10,
                          },
                          baselineColor: "#655B96",
                          baselineWidth:2,
                        },
                        hAxis: {
                          minValue: Math.min(
                            ...Object.keys(cptCodeDataResponse.chart_data).map(
                              Number
                            )
                          ),
                          maxValue: Math.max(
                            ...Object.keys(cptCodeDataResponse.chart_data).map(
                              Number
                            )
                          ),
                          ticks: Object.keys(
                            cptCodeDataResponse.chart_data
                          ).map(Number),
                          gridlines: {
                            color: "transparent",
                          },
                          baselineColor: "#655B96",
                          baselineWidth:2,
                          format: "####",
                        },
                        annotations: {
                          stem: {
                            color: "#655B96",
                          },
                          textStyle: {
                            opacity:0,
                          },
                          style: "line",
                        },
                        series: {
                          0: { color: "blue" },
                          1: { color: "red" },
                          2: { color: "green" },
                        },
                        pointSize: 5,
                        focusTarget: "category",
                      }}
                    />
                  )}
                </Box>
              )}
              {this.renderIf(
                tabValue === 2,
                <Box sx={styles.moreInfoBox}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={"4px 0px 36px"}
                  >
                    <Typography sx={styles.moreInfoText}>
                      Vorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Typography>
                    <img src={imgPlusButton} />
                  </Box>
                  <Divider />
                  <Box display="flex" alignItems="center" padding={"36px 0px"}>
                    <Box flex={1}>
                      <Typography sx={styles.moreInfoText}>
                        Norem ipsum dolor sit amet, consectetur adipiscing elit.
                      </Typography>
                    </Box>
                    <Box flex={2} sx={{ paddingLeft: "16px" }}>
                      <Typography sx={styles.moreInfoDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        id venenatis pretium risus euismod dictum egestas orci
                        netus feugiat ut egestas ut sagittis tincidunt phasellus
                        elit etiam cursus orci in. Id sed montes.
                      </Typography>
                    </Box>
                    <Box sx={{ paddingLeft: "16px" }}>
                      <img src={imgCloseButton} />
                    </Box>
                  </Box>
                  <Divider />
                  {this.moreInfoData.map((row) => (
                    <>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        padding={"36px 0px"}
                        key={row.id}
                      >
                        <Typography sx={styles.moreInfoText}>
                          {row.desciption}
                        </Typography>
                        <img src={imgPlusButton} />
                      </Box>
                      <Divider />
                    </>
                  ))}
                </Box>
              )}
            </Box>
            {this.renderIf(
              tabValue === 0,
              <Box sx={styles.footer}>
                <Typography sx={styles.footerHeadText}>
                  <b>
                    * <span style={{ marginRight: "30px" }}>N/A</span>Means that
                    our database does not have enough data to provide a cost
                    estimate for this procedure code in this geographic area.
                  </b>
                </Typography>
                <Typography sx={styles.footerText}>
                  FAIR Health's cost estimates only apply to people who have
                  private insurance, or who are uninsured. Our cost estimates
                  don't include government programs like Medicare, Medicaid or
                  military plans like Tricare. But, they may be helpful if
                  you're comparing costs for Medicare Advantage Plans, where you
                  often pay more if you go outside your plan network.
                </Typography>
                <Typography sx={{ ...styles.footerText, padding: 0 }}>
                  Current Procedural Terminology Codes ("CPT") displayed on the
                  site are owned and copyrighted by the American Medical
                  Association ("AMA") and licensed to FAIR Health.{" "}
                </Typography>
                <Typography sx={{ ...styles.footerText, paddingBottom: 0 }}>
                  CPT copyright 2023 American Medical Association.{" "}
                </Typography>
                <Typography sx={{ ...styles.footerText, paddingBottom: 0 }}>
                  FAIR Health uses commercial claims data and data from the
                  Medicare Qualified Entity Certification Program to create
                  benchmarks. Only commercial in-network rates are used to
                  calculate the in-network price.{" "}
                </Typography>
                <Typography sx={{ ...styles.footerText, paddingBottom: 0 }}>
                  <Box component="span" display="block">
                    Prices are based on FH<sup>®</sup>
                  </Box>
                  <Box component="span" display="block">
                    Benchmarks
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  moreInfoDescription:{
    color:"#94A3B8",
    fontSize:"14px",
    fontWeight:"400",
  },
  moreInfoText:{
    color:"#1E293B",
    fontWeight:"500",
    fontSize:"18px",
  },
  yearOverYearBox:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop: "26px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid #D4D2E3",
  },
  moreInfoBox:{
    height: '70%',
    overflowY: "auto",
    marginTop: "26px",
    padding: "32px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid #D4D2E3",
    '::-webkit-scrollbar': {
      width: '12px !important',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#CBD5E1 !important',
      borderRadius: '10px !important',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#94A3B8 !important',
      borderRadius: '10px !important',
      transition: 'opacity 0.3s !important',
    },
  },
  tableModalBodyCell:{
    padding: "16px",
    color: '#1E293B',
    fontSize: "18px",
    fontWeight: '700',
  },
  modal:{
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding:"8px",
  },
  closeIcon:{
    backgroundColor: '#595b5c',
    width: "30px",
    height: "30px",
    borderRadius: "5px",
  },
  modalInner:{
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    maxWidth: "1284px",
    transform: "Translate(-50%, -50%)",
  },
  footerText:{
    color: '#64748B',
    fontSize: '14px',
    fontWeight: '400',
    padding: "20px 0px",
    width:"50%",
  },
  footerHeadText:{
    color: '#64748B',
    fontSize: '14px',
    fontWeight: '500',
    width:"50%",
  },
  footer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: "60px",
  },
  simmiliarButton:{
    fontSize: "16px",
    fontWeight: "500",
    cursor: 'pointer',
    backgroundColor: '#F69544',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#F69544',
    },
  },
  innerBoxText:{
    color: '#94A3B8',
    fontSize: '18px',
    fontWeight: '400',
  },
  innerBoxTextModal:{
    color: '#64748B',
    fontSize: '18px',
    fontWeight: '400',
  },
  tableBodyCell:{
    padding: "40px 22px 24px",
    color: '#1E293B',
    fontSize: "18px",
    fontWeight: '700',
    borderBottom: 'none',
  },
  tableHeadCell:{
    padding: "25px 20px 20px",
    backgroundColor: '#334155',
    color: '#FFFFFF',
    fontSize: "20px",
    fontWeight: '600',
  },
  tableContainer:{
    width: '100%',
  },
  tabPanel1:{
    padding:"8px !important",
    backgroundColor: '#fff !important',
  },
  tab:{
    padding: "0px 10px 0px !important",
    textTransform: "none",
    fontSize: '20px',
  },
  innerTab:{
    paddingTop: "38px",
    paddingBottom: "24px",
  },
  tabRoot: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding:'16px 45px 16px 45px',
    fontWeight: 600,
    fontSize: '15px',
    backgroundColor: '#fff',
    '&.Mui-selected': {
      backgroundColor: '#1799CB',
      color: '#ffffff',
      borderRadius: '25px',
    },
    '&:not(.Mui-selected)': {
      backgroundColor: '#fff',
      color: '#94A3B8',
    },
  },
  firstTab: {
    borderRadius: '25px 0 0 25px',
  },
  lastTab: {
    borderRadius: '0 25px 25px 0',
  },
  contentBox:{
    backgroundColor: "#ECFAFF",
    padding: "40px 40px 0px",
    borderRadius: "0px 0px 16px 16px !important",
  },
  contentBoxForNextTabs:{
    backgroundColor: "#ECFAFF",
    padding: "40px",
    borderRadius: "16px !important",
  },
  container: {
    padding: "40px",
    borderRadius: "16px", 
  },
  innerContainer: {
    height:'100%',
  },
  header:{
    padding: "30px",
    backgroundColor: "#1E293B !important",
    borderRadius: "16px 16px 0px 0px",
  },
  title:{
    color: "#ffffff",
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "8px",
  },
  subTitle:{
    color: "#D1D5DB",
    fontSize: "14px",
    fontWeight: "300",
    marginBottom: "8px",
  },
  cityName:{
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "italic",
    alignSelf: "flex-start",
  },
  percentileText:{
    color: "#ffffff",
    fontSize: "13px",
    fontWeight: "400",
    fontStyle: "italic",
    alignSelf: "flex-end",
  },
}
// Customizable Area End
